import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { Link, Route } from 'react-router-dom';
import JWTPublicKey from '../../data/JWTPublicKey';
import AssessmentTypesGrid from '../AssessmentTypesGrid';
import MainscaleWorkflow from '../MainscaleWorkflow';
import SupplementscaleWorkflow from '../SupplementscaleWorkflow';
import MainSupplementeWorkflow from '../MainSupplementWorkflow';

const jwt = require('jsonwebtoken');

const JWT_PUBLIC_KEY = JWTPublicKey();

const errorDialog = () => {

    return (
        <Dialog
            open={true}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
        >
            <DialogTitle id="dialog-title">{"Fehler"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="dialog-description">
                    Leider ist ein Fehler aufgetreten. Bitte versuchen Sie sich erneut für die Nutzung von BEPKI zu registrieren.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button component={Link} to="/register" color="primary" autoFocus>
                    Zur Registrierung
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function NewAssessmentRoutes(props) {

    const [token, setToken] = useState(false);

    if (props.location.search && props.location.search !== "?new" && !token) {
        let token = props.location.search.substr(1); //cut of the ? at the beginning


        if (token) {
            setToken(token);
        }
    }

    if (!token) {
        return (<Redirect push to='/register' />);
    } else {


        let tokenVerified = jwt.verify(token, JWT_PUBLIC_KEY, { algorithms: ['RS256'] });
        if (tokenVerified) {
            // if (true) {
            return (
                <div>
                    <Route
                        exact path="/new-assessment"
                        render={(props) => (
                            <AssessmentTypesGrid {...props} token={token} />
                        )} />
                    <Route
                        exact path="/new-assessment/mainscale"
                        render={(props) => (
                            <MainscaleWorkflow {...props} token={token} />
                        )} />
                    <Route
                        exact path="/new-assessment/supplementscale"
                        render={(props) => (
                            <SupplementscaleWorkflow {...props} token={token} />
                        )} />
                    <Route
                        exact path="/new-assessment/main+supplement"
                        render={(props) => (
                            <MainSupplementeWorkflow {...props} token={token} />
                        )} />
                    <Route
                        exact path="/new-assessment/main+extended"
                        render={(props) => (
                            <MainscaleWorkflow {...props} token={token} extended={true} />
                        )} />
                    <Route
                        exact path="/new-assessment/main+extended+supplement"
                        render={(props) => (
                            <MainSupplementeWorkflow {...props} token={token} extended={true} />
                        )} />
                </div>
            );
        } else {
            //TODO: Display error dialog, email is invalid
            return errorDialog();
        }
    }
}