import React from 'react';
import PersonFormCard from './PersonFormCard';
import PersonCard from './PersonCard';
import Box from '@material-ui/core/Box';
import MainscalePdfExporter from '../exporter/MainscalePdfExporter';
import SupplementscalePdfExporter from '../exporter/SupplementscalePdfExporter';
import Button from '@material-ui/core/Button';
import MainscaleWorkflow from './MainscaleWorkflow';
import SupplementscaleWorkflow from './SupplementscaleWorkflow';
import Axios from 'axios';
import MainscaleData from "../data/MainscaleData";
import SupplementscaleData from "../data/SupplementscaleData";
import SupplementscaleItem from "./SupplementscaleItem";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

// const ITEMS_API_ENDPOINT = "https://oia5jswo8i.execute-api.eu-central-1.amazonaws.com/dev/items";
const ITEMS_API_ENDPOINT = BACKEND_URL + "/items";

export default class MainSupplementeWorkflow extends React.Component {
    constructor(props) {
        super(props);


        let stateObj = {
            mainItems: null,
            supplementItems: null,
            personData: null,
            workflowStep: 1,
            unlockSupplementButton: false,
            unlockResultButton: false,
            downloadMainscalePdf: null,
            downloadSupplementscalePdf: null
        };

        this.state = stateObj;

        this.handleData = this.handleData.bind(this);
        this.handleFinishedMain = this.handleFinishedMain.bind(this);
        this.handleFinishedSupp = this.handleFinishedSupp.bind(this);
        this.navigateToSupplement = this.navigateToSupplement.bind(this);
        this.navigateToResult = this.navigateToResult.bind(this);
        this.setDownloadFnMain = this.setDownloadFnMain.bind(this);
        this.setDownloadFnSupp = this.setDownloadFnSupp.bind(this);
    }

    setDownloadFnMain(fn) {
        this.setState({
            downloadMainscalePdf: fn
        });
    }

    setDownloadFnSupp(fn) {
        this.setState({
            downloadSupplementscalePdf: fn
        });
    }

    handleData(data) {

        this.setState({
            personData: data,
            workflowStep: 2
        });

    };

    handleFinishedMain() {
        if (!this.state.unlockSupplementButton) {
            this.setState({
                unlockSupplementButton: true
            });
        }
    }

    navigateToSupplement() {
        this.setState({
            workflowStep: 3
        });
    }

    componentDidMount() {
        let productId = this.props.extended ? 5 : 3;
        if (this.state.workflowStep === 1) {
            const postData = {
                token: this.props.token,
                productId: productId
            }

            Axios({
                method: 'post',
                url: ITEMS_API_ENDPOINT,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: postData
            }).then((response) => {
                /*
                this.setState({
                    // mainItems: response.data.mainItems,
                    // supplementItems: response.data.supplementItems
                });
                */
            }).catch((error) => {
                console.log(error);
            });

            let mItems = MainscaleData();
            let sItems = SupplementscaleData();

            this.setState({
                mainItems: mItems,
                supplementItems: sItems
            });
        }
    }

    handleFinishedSupp() {
        if (!this.state.unlockResultButton) {
            this.setState({
                unlockResultButton: true
            });
        }
    }

    navigateToResult() {
        this.setState({
            workflowStep: 4
        });
    }

    render() {

        if (this.state.workflowStep === 1) {

            return (
                <Box m={2}>
                    <PersonFormCard onSubmit={this.handleData} />
                </Box>
            );
        } else if (this.state.workflowStep === 2) {
            return (
                <Box>
                    <Box m={0}>
                        <MainscaleWorkflow items={this.state.mainItems} handleFinished={this.handleFinishedMain} personData={this.state.personData} setDownloadFn={this.setDownloadFnMain} {...this.props} />
                    </Box>
                    <Box m={2}>
                        <Button variant="contained" color="secondary" disabled={!this.state.unlockSupplementButton} onClick={this.navigateToSupplement}>Weiter zur Erhebung der Ergänzungsskala</Button>
                    </Box>
                </Box>

            );
        } else if (this.state.workflowStep === 3) {
            return (
                <Box>
                    <Box m={0}>
                        <SupplementscaleWorkflow items={this.state.supplementItems} handleFinished={this.handleFinishedSupp} personData={this.state.personData} setDownloadFn={this.setDownloadFnSupp} {...this.props} />
                    </Box>
                    <Box m={2}>
                        <Button variant="contained" color="secondary" disabled={!this.state.unlockResultButton} onClick={this.navigateToResult}>Weiter zum Gesamtergebnis</Button>
                    </Box>
                </Box>
            );
        } else if (this.state.workflowStep === 4) {
            return (
                <Box>
                    <Box m={2}>
                        <PersonCard patient={this.state.personData.patient} assessor={this.state.personData.assessor} />
                    </Box>
                    <Box m={2}>
                        <Button variant="contained" color="primary" onClick={this.state.downloadMainscalePdf}>Ergebnisse der Grundskala herunterladen</Button>
                    </Box>
                    <Box m={2}>
                        <Button variant="contained" color="primary" onClick={this.state.downloadSupplementscalePdf}>Ergebnisse der Ergänzungsskala herunterladen</Button>
                    </Box>
                </Box>
            );
        }
    }
}