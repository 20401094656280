import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './views/App';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { deDE } from '@material-ui/core/locale';
import reportWebVitals from './reportWebVitals';

import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"

// TODO: remove this temporary redirect again, when not needed anymore
if ((window.location.hostname !== "bep-ki-sedip.de"
    && window.location.hostname !== "test.bep-ki-sedip.de"
    && window.location.hostname !== "dev.bep-ki-sedip.de")
    && window.location.hostname !== "localhost") {

    const domain = "bep-ki-sedip.de";
    const subdomain = window.location.hostname.split('.').slice(0, -2).join('.');
    window.location.replace("https://" + (subdomain ? subdomain + "." : "") + domain);
}

const theme = createMuiTheme({
  // palette: {
  //   primary: { main: '#1976d2' },
  // },
  typography: {
    fontSize: 12,
  },
}, deDE);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
