import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import { createCanvas } from 'canvas';
import MainscaleCanvas from '../views/MainscaleCanvas';
import DevelopmentTasksCanvas from "../views/DevelopmentTasksCanvas";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';


export default class MainscalePdfExporter {
    constructor(items, questionaireType, patient, assessor) {
        //just create a canvas, width and height will be calculated later
        this.items = items;
        this.questionaireType = questionaireType;
        this.patient = patient;
        this.assessor = assessor;

        let tableItems = this.getItemsInTableFormat();

        this.docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            header: this.addHeaders(),
            footer: function(currentPage, pageCount) {
                    return {text: 'Seite ' + currentPage.toString() + '/' + pageCount, alignment: 'center'};
                },
            content: [
                {
                    table: {
                        headerRows: 3,
                        dontBreakRows: true,
                        body: [
                            [{}, {}, {}, {}, {text: 'Wert', style: 'tableHeader', colSpan: 5, alignment: 'center'}, {}, {}, {}, {}],
                            [{}, {}, {}, {}, {text: '1', style: 'tableHeader', rowSpan: 1}, {text: '2', style: 'tableHeader', colSpan: 3, alignment: 'center'},{}, {}, {text: '3', style: 'tableHeader', rowSpan: 1}],
                            [{text: 'Dim.', style: 'tableHeader', rowSpan: 1}, {text: 'Typ', style: 'tableHeader', rowSpan: 1}, {text: 'Text', style: 'tableHeader', rowSpan: 1}, {text: 'Kommentar', style: 'tableHeader', rowSpan: 1}, {}, {text: 'a', style: 'tableHeader'}, {text: 'b', style: 'tableHeader'}, {text: 'c', style: 'tableHeader'}, {}],
                            ...tableItems,
                        ]
                    },
                    layout: {
                        fillColor: function (rowIndex, node, columnIndex) {
                            if (rowIndex <= 2) {
                                return '#2980ba';
                            } else {
                                return (rowIndex % 2 === 0) ?  null : '#f5f5f5';
                            }
                        },
                        hLineWidth: function (i, node, columnIndex) {
                            return (i === 2 && columnIndex === 4) ?  0 : 1;
                        },
                        hLineColor: function (i, node, columnIndex) {

                            if (i === 2 && (columnIndex === 4 || columnIndex === 8)) {
                                return '#2980ba';
                            }
                            if (i >= 3 || i === 0 || (columnIndex >= 4 && i <= 3)) {
                                return '#c7c7c7';
                            } else {
                                return '#2980ba';
                            }
                        },
                        vLineWidth: function (i, node) { return 1; },
                        vLineColor: function (i, node) { return '#c7c7c7'; },
                    },
                },
                {
                    image: this.addMainscaleCanvasToPdf(),
                    fit: [742, 495],
                    pageBreak: 'before',
                    pageOrientation: 'landscape'
                },
                {
                    image: this.addDevelopmentTasksCanvasToPdf(),
                    fit: [395, 642],
                    pageBreak: 'before',
                    pageOrientation: 'portrait'
                }
            ],
            defaultStyle: {
                color: '#505050',
            },
            styles: {
                tableHeader: {
                    bold: true,
                    fontSize: 12,
                    color: 'white',
                },
                pageHeader: {
                    fontSize: 10,
                    margin: [25, 25, 25, 25],
                    width: '*'
                }
            },
        };
    }

    getItemsInTableFormat() {
        let itemList = [];

        this.items.forEach(element => {

            let text = {text: this.getEmphasizedTextAsArray(element.itemText)};
            let comment = {text: this.getEmphasizedTextAsArray(element.itemComment)};

            let item = [element.itemCategory.categoryAbbrv + "-" + element.itemCategory.itemCategoryId, element.itemType, text, comment];

            if (element.itemValue === "1") {
                item.push("x");
            } else {
                item.push("");
            }

            if (element.itemValue === "2a") {
                item.push("x");
            } else {
                item.push("");
            }

            if (element.itemValue === "2b") {
                item.push("x");
            } else {
                item.push("");
            }

            if (element.itemValue === "2c") {
                item.push("x");
            } else {
                item.push("");
            }

            if (element.itemValue === "3") {
                item.push("x");
            } else {
                item.push("");
            }

            //only filled elements should be added
            if (element.itemValue !== null) {
                itemList.push(item);
            }
        });

        return itemList;
    }

    createPdf() {
        //this.pdf.save(this.patient.firstname + "_" + this.patient.lastname + "_" + this.patient.birthday.toLocaleDateString("de-DE") + "_Hauptskala.pdf");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.createPdf(this.docDefinition).download(this.patient.firstname + "_" + this.patient.lastname + "_" + this.patient.birthday.toLocaleDateString("de-DE") + "_Grundskala.pdf");

    }

    addMainscaleCanvasToPdf() {
        const canvas = createCanvas(0, 0);
        const mainscaleCanvas = new MainscaleCanvas(canvas, this.items, this.questionaireType, true);

        canvas.width = mainscaleCanvas.calculateCanvasWidth();
        canvas.height = mainscaleCanvas.calculateCanvasHeight();

        mainscaleCanvas.draw();

        let imgData = canvas.toDataURL("image/jpeg", 1.0);

        return imgData;
    }

    addDevelopmentTasksCanvasToPdf() {
        const canvas = createCanvas(0, 0);
        const developmentTasksCanvas = new DevelopmentTasksCanvas(canvas, this.items, true);

        canvas.width = developmentTasksCanvas.calculateCanvasWidth();
        canvas.height = developmentTasksCanvas.calculateCanvasHeight();

        developmentTasksCanvas.draw();

        let imgData = canvas.toDataURL("image/jpeg", 1.0);

        return imgData;
    }

    addHeaders() {
        return { columns: [
                {text: 'Klient/in: ' + this.patient.firstname + ' ' + this.patient.lastname + ', geb. ' + this.patient.birthday.toLocaleDateString("de-DE"),alignment: 'left', style: 'pageHeader'},
                {text: 'Datum: ' + new Date().toLocaleDateString("de-DE"), alignment: 'center', style: 'pageHeader'},
                {text: 'Einschätzer/in: ' + this.assessor.firstname + ' ' + this.assessor.lastname, alignment: 'right', style: 'pageHeader'}
                ]
        };
    }

    getEmphasizedTextAsArray(text) {

        if (text.indexOf('<em>') === -1) {
            return text;
        }

        let isEmphasized = text.indexOf('<em>') === 0;

        let arrText = [];
        let emphasizedParts = text.split(/<\/?em>/);


        for (let i = 0; i < emphasizedParts.length; i++) {
            if (isEmphasized) {
                if (i === 0) {
                    i++;
                }
                arrText.push({text: emphasizedParts[i], decoration: 'underline'});
                isEmphasized = false;
            } else {
                arrText.push({text: emphasizedParts[i]});
                isEmphasized = true;
            }

        }

        return arrText;
    }
}



