export default function MainscaleData() {

    return [
        {
            itemId: 1,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 1 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [0, 4],
            itemText: "genießt passiv (z.B. entspannt sich beim Baden / Musikhören / In-der-Sonne-Sitzen)",
            itemComment: "empfangende Genussfähigkeit, auch als Teil der Sexualität im weitesten Sinne",
            itemValue: null
        },
        {
            itemId: 2,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 2 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [0, 4],
            itemText: "<em>übernimmt die Stimmung</em> der Bezugsperson / Umgebung <em>unmittelbar</em> (z.B. Stress, Ausgeglichenheit), d.h. reagiert auf die Atmosphäre",
            itemComment: "Affektansteckung; geschieht automatisch, d.h. unbewusst. Zeichen fehlender Abgrenzungsfähigkeit.",
            itemSuccessor: 26,
            itemValue: null
        },
        {
            itemId: 3,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 3 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [0, 4],
            itemText: "reagiert <em>bei kürzerem Alleinsein</em> (ca. 15 min.) mit Passivität, Weinen, suchthaftem Verhalten, Spannung oder Unruhe",
            itemComment: "Verlassenheitsreaktion; Angst setzt erst bei / nach der Trennung ein",
            itemSuccessor: 6,
            itemValue: null
        },
        {
            itemId: 4,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 4 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [0, 4],
            itemText: "findet bei Missbehagen <em>nur</em> durch die <em>unmittelbare Zuwendung</em> (z.B. durch freundliche Ansprache, Körperkontakt, daneben sitzen) <em>und Situationsgestaltung</em> einer einfühlsam reagierenden Bezugsperson (die z.B. das Reizniveau steuert, Bedürfnisse befriedigt) zu emotionaler Ausgeglichenheit; erfolgt dies nicht, verfällt er in Apathie / Unruhe",
            itemComment: "Affektregulierung = Steuerung der Ausdrucksstärke von Gefühlen; gemeint ist sowohl die Besänftigung von Angst, Wut, Verzweiflung als auch die Überwindung von emotionaler Ausdruckslosig-keit und Interesselosigkeit",
            itemSuccessor: 6,
            itemValue: null
        },
        {
            itemId: 5,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 5 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [4, 8],
            itemText: "beschäftigt sich interessiert und aufmerksam <em>nur</em>, wenn die Bezugsperson physisch und psychisch ansprechbar <em>im Raum</em> ist, d.h. diese kann einer eigenen Tätigkeit nachgehen",
            itemComment: "beginende Selbstbeschäftigung; unsichtbares emotionales Band zur Bezugsperson, Sichtkontakt",
            itemSuccessor: 10,
            itemValue: null
        },
        {
            itemId: 6,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 6 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [8, 12],
            itemText: "benutzt, um schwierige Situationen (z.B. kurzfristige Trennung - ca. 15 Minuten) zu meistern, einen <em>Lieblingsgegenstand</em> (z.B. Schmusedecke, Stofftier, Kleidungsstück der Bezugsperson)",
            itemComment: "Übergangsobjekt ist Stellvertreter für die Bezugsperson und die eigene Person; dient dazu, Übergänge u. andere Situationen ohne die Bezugsperson zu erleichtern, hilft also zur Verselbständigung und Zuwendung zur Welt",
            itemSuccessor: 14,
            itemValue: null
        },
        {
            itemId: 7,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 7 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [8, 12],
            itemText: "<em>hat oft nicht zu beruhigende Erregungszustände</em> bis hin zum Kontrollverlust (erkennbar an \"irrem\" Blick, fehlender Ansprechbarkeit) <em>bei geringfügigen oder nicht erkennbaren Anlässen</em> (z.B. geringfügige Einschränkungen, Störung eines gewohnten Ablaufs, Unterbrechen einer Tätigkeit, kurzes Warten)",
            itemComment: "Erlebenshintergrund: existentiell bedrohliche Verwirrung und Desorientierung, Verlust der Selbststeuerung",
            itemSuccessor: 30,
            itemValue: null
        },
        {
            itemId: 8,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 8 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [12, 18],
            itemText: "freut sich an seinem Körper (Bewegung, Größe, Schnelligkeit, Stärke)",
            itemComment: "positives Körpererleben auch als Bestandteil sexueller Grenussfähigkeit im weitesten Sinne",
            itemValue: null
        },
        {
            itemId: 9,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 9 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [12, 18],
            itemText: "ist stolz auf sein Tun <em>unabhängig von der Qualität des Ergebnisses</em> oder <em>dessen Wirkung</em> auf andere (z.B. strahlt, aufrechte Körperhaltung, will Bestätigung)",
            itemComment: "Das Tun als solches reicht für den Stolz; Selbstwirksamkeit als Wurzel für die spätere Leistungsmotivation",
            itemSuccessor: 12,
            itemValue: null
        },
        {
            itemId: 10,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 10 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [12, 18],
            itemText: "beschäftigt sich 15 - 20 min. aufmerksam allein, wenn die Bezugsperson zwar nicht sichtbar, aber <em>in der Nähe und erreichbar</em> (z.B. im Nebenzimmer) ist",
            itemComment: "zunehmende Fähigkeit zur Selbstbeschäftigung. Zeichen zunehmender Autonomie",
            itemSuccessor: 18,
            itemValue: null
        },
        {
            itemId: 11,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 11 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [18, 24],
            itemText: "verweigert sich / benutzt \"Nein\" nicht sachbezogen, nicht aus Überforderung mit der Situation, sondern <em>zur Selbstbehauptung</em> (z.B. verweigert etwas, was er mag; lehnt Hilfe ab)",
            itemComment: "Angst vor Autonomieverlust",
            itemSuccessor: 36,
            itemValue: null
        },
        {
            itemId: 12,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 12 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [24, 30],
            itemText: "reagiert bei Misserfolg  mit heftiger Wut / Verzweiflung <em>ohne</em> Kontrollverlust (z.B. Schreien, Um-Sich-Schlagen)",
            itemComment: "geringe Frustrationstoleranz, weil er sich seiner begrenzten Fähigkeiten u. Abhängigkeit bewusst wird",
            itemSuccessor: 17,
            itemValue: null
        },
        {
            itemId: 13,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 13 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [24, 30],
            itemText: "äußert klare Geschmacksvorstellungen oder Vorlieben (z.B. Lieblingsgericht, bestimmte Kleidung, Tätigkeiten)",
            itemComment: "Zeichen von Autonomie",
            itemValue: null
        },
        {
            itemId: 14,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 14 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [30, 36],
            itemText: "stabilisiert sich in emotional schwierigen Alltagssituationen <em>innerhalb kurzer Zeit</em> (ca. 5 Minuten) durch <em>kurze freundliche,</em> situationsbezogene <em>Ansprache</em> der Bezugsperson (\"kann jedem mal passieren..., das nächste Mal schaffst du es..., xy kommt wieder\") <em>oder</em> durch vergleichbare <em>verbale Selbstberuhigung</em>)",
            itemComment: "hat die konstruktiven Bewältigungsformen der \"guten\" Bezugsperson verinnerlicht; Bestandteil der sicheren Bindung",
            itemValue: null
        },
        {
            itemId: 15,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 15 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [30, 36],
            itemText: "versucht nach Fehlverhalten negative Reaktionen der Bezugsperson zu verhindern, <em>bevor</em> diese reagiert (z.B. passt sich an, will wieder \"lieb\" sein, entschuldigt sich), weil er sich seiner Normübertretung bewusst ist",
            itemComment: "Angst vor Strafe und Liebesverlust als Weiterentwicklung der Trennungsangst; Beginn des Schuldbewusstseins. Normen sind ansatzweise verinnerlicht",
            itemValue: null
        },
        {
            itemId: 16,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 16 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [36, 48],
            itemText: "hält viele Gebote <em>für begrenzte Zeit</em> (ca. 30 Minuten) ohne Aufsicht ein",
            itemComment: "Zeichen von wachsender Selbstkontrolle und Normübernahme",
            itemSuccessor: 39,
            itemValue: null
        },
        {
            itemId: 17,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 17 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [36, 48],
            itemText: "bemüht sich <em>Aufgaben</em> / Vorhaben <em>zunächst allein</em> zu bewältigen, sucht jedoch <em>vertrauensvoll Hilfe</em>, wenn es ihm nicht gelingt",
            itemComment: "Bestandteil der sicheren Bindung, emotionale Konstanz, d.h. verbindet Autonomie mit sozialer Bezogenheit",
            itemValue: null
        },
        {
            itemId: 18,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 18 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "nutzt ein <em>breites</em> Repertoire (<em>mindestens fünf</em> Möglichkeiten) und beschäftigt sich damit <em>allein mindestens 30-45 Minuten</em> aktiv, solange er jederzeit eine Bezugsperson aufsuchen kann",
            itemComment: "Zeichen von Autonomie und Interessensvielfalt",
            itemValue: null
        },
        {
            itemId: 19,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 19 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "schätzt seine Fähigkeiten <em>häufig realistisch</em> ein (z.B. weiß, dass er neue Aufgaben erst üben muss; setzt sich relativ realistische Ziele)",
            itemComment: "beginnende Fähigkeit zur Selbstreflexion; Erfolgsorientierung als Aspekt der Leistungsmotivation",
            itemValue: null
        },
        {
            itemId: 20,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 20 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "reguliert <em>Affekte mittlerer Stärke</em> (z. B. Ärger, Freude, Traurigkeit) auf <em>unterschiedliche</em> Weise <em>konstruktiv</em>; d.h. nutzt ein breites Verhaltensrepertoire von mindestens drei Möglichkeiten (z.B. Lieblingsgegenstand, eine Lieblingsbeschäftigung, Trost suchen bei einer Bezugsperson, gedankliche Selbstberuhigung)",
            itemComment: "besitzt Affekt- und Impulskontrolle als Teil der Selbstkontrolle",
            itemValue: null
        },
        {
            itemId: 21,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 21 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "reflektiert mit der Bezugsperson <em>von sich aus</em> alle ihn beschäftigenden \"Lebensfragen\" (z.B. \"Warum schlägt X Schwächere und hört nicht auf, wenn sie weinen?\", \"Warum kann ich das nicht, obwohl ich mich anstrenge?\") und fragt gezielt weiter, bis ihm die Erklärung genügt",
            itemComment: "Bezugsperson als \"Weltdeuter\" und \"Lebensberater\"",
            itemValue: null
        },
        {
            itemId: 22,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 22 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "beschäftigt sich <em>allein ausdauernd und konzentriert (mind. 1 Std.)</em> mit einer <em>vorgegebenen</em> Aufgabe (z.B. Hausaufgaben, Haushaltstätigkeiten, WfbM-Arbeiten)",
            itemComment: "Anstrengungsbereitschaft als Teil der Leistungsmotivation",
            itemValue: null
        },
        {
            itemId: 23,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 23 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [144, 216],
            itemText: "setzt sich allein oder mit Gleichaltrigen <em>differenziert</em> mit verschiedenen Themen seiner Lebenssituation auseinander (Familie, Herkunft, sozialer Status, Behinderung, Zukunftspläne)",
            itemComment: "differenzierte Selbstreflexion",
            itemValue: null
        },
        {
            itemId: 24,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 24 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [216, 1188],
            itemText: "trifft <em>weitreichende</em> Entscheidungen <em>selbstverantwortlich</em> und geht auch mit unangenehmen Konsequenzen konstruktiv um, d.h. kann sich gut einschätzen, kontrollieren und durchsetzen",
            itemComment: "Merkmal der erwachsenen selbstverantwortlichen Persönlichkeit",
            itemValue: null
        },
        {
            itemId: 25,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 1 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [0, 4],
            itemText: "reagiert positiv, wenn seine Signale (z.B. Mimik, Gestik, Laute, ggf. Wörter) aufgegriffen werden, antwortet (evtl. mit Variationen), so dass ein Spiegeldialog entsteht",
            itemComment: "Primärer Dialog; Zeichen von entstehender Bindung, beginnender Autonomie und Interesse am Dialog; erlebt dabei seine Selbstwirksamkeit",
            itemSuccessor: 26,
            itemValue: null
        },
        {
            itemId: 26,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 2 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [8, 12],
            itemText: "achtet auf den emotionalen Ausdruck (Mimik, Stimmklang) der Bezugsperson und <em>reagiert passend</em> (z.B. ist sensibel für Lob und Ärger oder Freude und Schreck der Bezugsperson)",
            itemComment: "Affektabstimmung: bewusste Aufnahme des Gefühls vom Gegenüber; dies führt zum Lernen von Gefühlsreaktionen, Voraussetzung für Empathie und Dialogfähigkeit",
            itemValue: null
        },
        {
            itemId: 27,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 3 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [8, 12],
            itemText: "sucht bei Selbstbeschäftigung in <em>kurzen</em> Abständen (bis zu ca. 15 Minuten) Blick- / Körperkontakt zur Bezugsperson, um bei ihr emotional aufzutanken oder um Trost bzw. Schutz zu holen",
            itemComment: "Bezugsperson ist Heimatstützpunkt (\"unsichtbares Band\"); Zeichen für entstehende Bindung",
            itemSuccessor: 10,
            itemValue: null
        },
        {
            itemId: 28,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 4 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [12, 18],
            itemText: "befolgt \"Nein\" <em>nur</em> in Gegenwart der Bezugsperson, solange sie auf die Einhaltung achtet",
            itemComment: "\"Nein\" hat losgelöst von der Bezugsperson keine Bedeutung, oder die Selbstkontrolle fehlt.",
            itemSuccessor: 16,
            itemValue: null
        },
        {
            itemId: 29,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 5 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [18, 24],
            itemText: "versucht Trennung und / oder Alleinsein <em>aktiv</em> zu verhindern, (z.B. beschattet die Bezugsperson; zieht die Aufmerksamkeit auf sich)",
            itemComment: "Verlassenheitsangst; setzt voraus, dass er sich die Folgen der Trennung, nämlich Alleinsein, vorstellen kann",
            itemSuccessor: 14,
            itemValue: null
        },
        {
            itemId: 30,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 6 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [18, 24],
            itemText: "wird bei Widerstand <em>ohne</em> Kontrollverlust wütend / schreit, wenn die Bezugsperson Grenzen setzt oder einen eigenen Willen bekundet",
            itemComment: "Wut mit geringfügiger Selbststeuerung; kämpft um seine Autonomie, ohne die Symbiose verlieren zu wollen. Angst vor Symbiose- und Autonomieverlust",
            itemSuccessor: 36,
            itemValue: null
        },
        {
            itemId: 31,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 7 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [18, 24],
            itemText: "bittet um Hilfe <em>und wartet</em> auf die Erledigung der Bitte",
            itemComment: "Zeichen von beginnender Impulskontrolle",
            itemValue: null
        },
        {
            itemId: 32,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 8 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [24, 30],
            itemText: "<em>testet</em> immer wieder in vielen - auch bekannten Situationen - die Gültigkeit von Ge- und Verboten (z.B. bei unterschiedlichen Personen, zu verschiedenen Zeiten)",
            itemComment: "dient dazu, die Allgemeingültigkeit von Ver- / Geboten zu erfassen und die Zuverlässigkeit der Bezugsperson zu überprüfen",
            itemSuccessor: 16,
            itemValue: null
        },
        {
            itemId: 33,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 9 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [24, 30],
            itemText: "zeigt Empathie <em>nur</em> dann, wenn eigene Interessen nicht betroffen sind (z.B. fragt nach; tröstet, wenn jemand traurig ist)",
            itemComment: "Affektabgleich und nachahmende empathische Reaktion, ohne eigene Bedürfnisse zurückstellen zu können",
            itemSuccessor: 40,
            itemValue: null
        },
        {
            itemId: 34,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 10 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [30, 36],
            itemText: "nimmt das Angebot, bei einfachen Haushaltstätigkeiten zu helfen, gerne an (z.B. Kochen, Tisch decken)",
            itemComment: "beginnende Leistungsmotivation; Erleben von Symbiose und Kompetenz im gemeinsamen Tun",
            itemValue: null
        },
        {
            itemId: 35,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 11 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [30, 36],
            itemText: "teilt begehrte Dinge <em>bereitwillig</em> mit Anderen (z. B. Süßigkeiten)",
            itemComment: "Zeichen erfolgreicher Normübernahme",
            itemValue: null
        },
        {
            itemId: 36,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 12 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [30, 36],
            itemText: "akzeptiert bei <em>Widersprüchen</em> zwischen seinen Absichten und den Wünschen der Bezugsperson <em>\"Mitttelwege\"</em> (z.B. Kompromisse, Alternativen, Aufschub auf späteren Zeitpunkt",
            itemComment: "Zeichen der emotionalen Konstanz oder Ausdruck der Überanpassung",
            itemValue: null
        },
        {
            itemId: 37,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 13 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [36, 48],
            itemText: "gestaltet im alltäglichen Umfeld aus <em>eigenem Antrieb</em> einfache gelingende gemeinsame Aktivitäten mit \"gleichrangigen\" vertrauten Personen <em>ohne</em> die primäre Bezugsperson (z.B. innerhalb der Tagessbetreuung, Freunde)",
            itemComment: "Zeichen von sozialer Kompetenz",
            itemValue: null
        },
        {
            itemId: 38,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 14 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [48, 60],
            itemText: "kämpft mit sozial <em>unerwünschten</em> Verhaltensweisen um seinen <em>Platz in der Gruppe</em> (z.B. petzt, rivalisiert, drangsaliert, ist geltungsbedürftig)",
            itemComment: "will die Aufmerksamkeit der Gruppe und der Bezugsperson; Angst in der Gruppe unterzugehen",
            itemSuccessor: 46,
            itemValue: null
        },
        {
            itemId: 39,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 15 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "passt sich <em>bewusst</em> Gruppennormen und Verhaltenserwartungen an, die <em>seinem momentanen Bedürfnis widersprechen</em>, d.h. kennt die Regeln und hält sie ein (z.B. respektiert das Eigentum Anderer, obwohl er es gerne hätte)",
            itemComment: "Teil der Gruppenfähigkeit, Normbewusstsein und Selbstkontrolle",
            itemValue: null
        },
        {
            itemId: 40,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 16 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [48, 60],
            itemText: "versteht <em>nur nach Erklärung</em>, dass andere Menschen etwas anderes sehen, wollen, fühlen als er selbst, und verhält sich dann entsprechend (z.B. stellt sein eigenes Bedürfnis zurück)",
            itemComment: "egozentrisches Denken tritt zurück, Einfühlungsvermögen wächst",
            itemSuccessor: 44,
            itemValue: null
        },
        {
            itemId: 41,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 17 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "<em>löst kleinere soziale Konflikte</em> mit <em>\"Gleichrangigen\" selbständig</em> und und für alle Beteiligten <em>befriedigend</em> (z. B. Kompromiss, akzeptiert Alternativen)",
            itemComment: "Bestandteil der sicheren Bindung, emotionale Konstanz",
            itemValue: null
        },
        {
            itemId: 42,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 18 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "zeigt werbende Verhaltensweisen (z.B. Flirten, Schmeicheln, Fürsorglichkeit, männliche Kraft, sich schön machen)",
            itemComment: "dient dem Aufbau oder der Darstellung der Geschlechtsrolle",
            itemValue: null
        },
        {
            itemId: 43,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 19 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "nimmt <em>auf eigenen Wunsch</em> gern allein an <em>regelmäßig</em> stattfindenden Freizeitgruppen teil (z.B. kreative Angebote, Sport, Tanz)",
            itemComment: "stabile Eigenmotivataion; Bestandteil der Gruppenfähigkeit, Aspekt der autonomen Teilhabe",
            itemValue: null
        },
        {
            itemId: 44,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 20 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "ist fähig, sich <em>von sich aus</em> in andere Menschen <em>hineinzuversetzen und</em> sein <em>Handeln darauf abzustimmen</em>",
            itemComment: "Perspektivwechsel; Einfühlungsvermögen; verbindet die eigene Sichtweise mit der des Gegenübers",
            itemValue: null
        },
        {
            itemId: 45,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 21 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "plant und verbringt seine Freizeit <em>selbständig</em> (allein oder mit Freunden) in der Öffentlichkeit (Café, Kino, Disco, Natur)",
            itemComment: "Aspekte der autonomen Teilhabe",
            itemValue: null
        },
        {
            itemId: 46,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 22 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "verhält sich <em>sozial kompetent</em> in der Gruppe (kann sich einfügen, durchsetzen und fair kämpfen)",
            itemComment: "Gruppenfähigkeit ist ausdifferenziert",
            itemValue: null
        },
        {
            itemId: 47,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 23 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [144, 216],
            itemText: "orientiert sich vorzugsweise an der <em>Gruppe der Gleichrangigen</em> und ihrer Idole, um sich <em>von Autoritäten abzugrenzen</em>",
            itemComment: "dient der Findung der eigenen Identität und der Verbundenheit mit Gleichrangigen",
            itemSuccessor: 50,
            itemValue: null
        },
        {
            itemId: 48,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 24 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [144, 216],
            itemText: " pflegt sexuell getönte Freundschaften",
            itemComment: "sexuelles Interesse spielt eine deutliche Rolle, nicht nur der Wunsch nach Geborgenheit",
            itemValue: null
        },
        {
            itemId: 49,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 25 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [144, 216],
            itemText: "strebt im Kontakt mit Bezugspersonen demonstrativ nach Selbstbestimmung",
            itemComment: "dient der Ablösung und Identitätsfindung",
            itemSuccessor: 24,
            itemValue: null
        },
        {
            itemId: 50,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 26 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [216, 1188],
            itemText: "übernimmt Verantwortung für selbst gewählte Beziehungen (Freunde, Partner, Familie) und berücksichtigt bei der Gestaltung angemessen die Bedürfnisse des Gegenübers",
            itemComment: "Merkmal ausgereifter und verantwortungsvoller Beziehungsfähigkeit",
            itemValue: null
        },
        {
            itemId: 51,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 1 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [0, 4],
            itemText: "führt willkürlich einfache <em>eingliedrige</em> Handlungen durch, die ein <em>sinnliches \"Erlebnis\"</em> vermitteln (z.B. nuckelt am Daumen, schlägt mit der Hand / dem Fuß gegen einen Gegenstand)",
            itemComment: "primäre Kreisreaktion, Sinn der Handlung liegt in der Selbstwahr-nehmung / im Tun",
            itemSuccessor: 52,
            itemValue: null
        },
        {
            itemId: 52,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 2 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [4, 8],
            itemText: "führt einfache <em>mehrgliedrige</em> Handlungen aus, die einen <em>Effekt</em> bewirken (z.B. nimmt einen Löffel, schlägt auf den Tisch, freut sich über das Geräusch)",
            itemComment: "sekundäre Kreisreaktion, erfasst den Wenn-dann-Zusammenhang; Ziele der Handlung sind nur der interessante Effekt und das Erleben von Selbstwirk-samkeit.",
            itemSuccessor: 55,
            itemValue: null
        },
        {
            itemId: 53,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 3 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [8, 12],
            itemText: "<em>wechselt</em> bei gemeinsamen Tätigkeiten mit seiner <em>Aufmerksamkeit</em> zwischen dem Gegenstand und dem Gegenüber (z.B. schlägt auf einen Gegenstand und schaut dann zur Bezugsperson), erkennbar am Blick und an Laut-/ Wortäußerungen",
            itemComment: "Referenzieller Blickkontakt, d.h. kann seine Aufmerksamkeit auf zwei Aspekte einer Situation richten; Voraussetzung für Spracherwerb und gemeinsame Tätigkeiten",
            itemValue: null
        },
        {
            itemId: 54,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 4 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [8, 12],
            itemText: "sucht und findet einen Gegenstand, wenn er das <em>Verschwinden beobachtet</em> und den <em>Aufbewahrungsort registriert</em> hat",
            itemComment: "Beginn der Objektpermanenz: weiß um die Existenz eines nicht unmittelbar vor Augen befindlichen Gegenstandes; beginnende Vorstellungskraft",
            itemSuccessor: 59,
            itemValue: null
        },
        {
            itemId: 55,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 5 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [8, 12],
            itemText: "löst <em>einfache</em> Aufgaben, indem er <em>bekannte</em> Handlungsschritte <em>zweckmäßig</em> hintereinanderschaltet (z.B. schiebt einen Gegenstand weg, um an einen anderen dahinter sichtbaren zu gelangen)",
            itemComment: "Mittel-Zweck Relation, beginnt seine Handlungsschritte in eine zweckmäßige Reihenfolge zu bringen; beginnende Vorstellungskraft",
            itemSuccessor: 57,
            itemValue: null
        },
        {
            itemId: 56,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 6 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [12, 18],
            itemText: "experimentiert <em>spielerisch und konzentriert</em> mit verschiedenen Materialien (z.B. Wasser, Sand, Suppe), d.h. variiert eine Handlung und beobachtet interessiert die Veränderung des Ergebnisses",
            itemComment: "tertiäre Kreisreaktion, experimentelles Funktionsspiel; Lust am Spiel, erlebnisbezogen, nicht zweck- oder ergebnisorientiert.",
            itemSuccessor: 58,
            itemValue: null
        },
        {
            itemId: 57,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 7 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [12, 18],
            itemText: "gebraucht im <em>Blickfeld befindliche Gegenstände als Hilfsmittel</em> (z.B. Löffel zum Essen; klettert auf einen Stuhl, um an einen Gegenstand zu gelangen) und setzt sie zweckmäßig als \"Werkzeug\" ein",
            itemComment: "Entdeckung der Werkzeugfunktion ",
            itemValue: null
        },
        {
            itemId: 58,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 8 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [18, 24],
            itemText: "probiert <em>gezielt</em> verschiedene Möglichkeiten, um ein einfaches Problem zu lösen (z.B. beim Puzzlen)",
            itemComment: "probiert gezielt mehrere Handlungsmuster aus, um ein Problem zu lösen, d.h. gezieltes Experimentieren; Lernen am Erfolg",
            itemValue: null
        },
        {
            itemId: 59,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 9 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [18, 24],
            itemText: "sucht einen Gegenstand, dessen <em>Verschwinden</em> er <em>nicht beobachtet</em> hat; d.h. sucht Dinge, die nicht am vertrauten Ort sind",
            itemComment: "Objektpermanenz weitgehend vorhanden",
            itemValue: null
        },
        {
            itemId: 60,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 10 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [18, 24],
            itemText: "übernimmt gehörte Wörter oder beobachtete Handlungen in das eigene Verhaltensrepertoire und zeigt sie zu einem <em>späteren Zeitpunkt</em> und in einer anderen Situation (z.B. im Alltag, Spiel)",
            itemComment: "ist fähig zur aufgeschobenen Nachahmung; Teil der Symbolfunktion, Lernen am Modell",
            itemValue: null
        },
        {
            itemId: 61,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 11 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [24, 30],
            itemText: "handelt so, als ob er glaubt, dass sein Gegenüber die Welt <em>genauso wahrnimmt wie er</em>, ebenso fühlt / denkt (z.B. geht davon aus, dass die eigene Lieblingsmusik allen gefällt; dass der Telefonpartner seine Gestik sieht)",
            itemComment: "denkt egozentrisch, d.h. geht beim Denken von sich aus, kann sich gedanklich nicht in einen anderen Menschen hineinversetzen",
            itemSuccessor: 40,
            itemValue: null
        },
        {
            itemId: 62,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 12 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [24, 30],
            itemText: "vermischt <em>ohne jeden Zweifel innere</em> (Phantasien, Wünsche, Ängste, Träume usw.) <em>und äußere Wirklichkeit</em> (z.B. sagt, dass er zuhause einen Hund hat, was seinem Wunsch, aber nicht der Realität entspricht)",
            itemComment: "Vorstellung und äußere Realität bilden noch eine Einheit",
            itemSuccessor: 68,
            itemValue: null
        },
        {
            itemId: 63,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 13 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [24, 30],
            itemText: "behandelt oder bezeichnet <em>alle Dinge als lebendig / menschlich</em> (z.B. die genau vor mir zugefallene Tür will mich ärgern), d.h. sie können sich freuen, traurig sein, Schmerzen fühlen oder jemanden ärgern",
            itemComment: "denkt animistisch / anthropomorph, d.h. macht keinen Unterschied zwischen belebt und unbelebt",
            itemSuccessor: 69,
            itemValue: null
        },
        {
            itemId: 64,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 14 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [36, 48],
            itemText: "erklärt sich Zusammenhänge auf <em>unrealistische</em> Weise,d.h. durch <em>beliebige Ähnlichkeiten</em> oder <em>besondere Kräfte</em> (z. B. wenn es draußen dunkel wird, macht jemand den Lichtschalter am Himmel aus. Wer mit dem weißen Würfel spielt gewinnt das Spiel)",
            itemComment: "denkt analog. Analogie wird nicht als Vergleich erkannt, sondern als Tatsachenbeschreibung aufgefasst",
            itemSuccessor: 76,
            itemValue: null
        },
        {
            itemId: 65,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 15 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [36, 48],
            itemText: "ordnet <em>passende</em> Gegenstände zu (z.B. jedem Teller eine Gabel, ein Messer), d. h. erkennt zusammengehörige Elemente",
            itemComment: "Weiterentwicklung der Kategorisierung; Eins-zu-Eins-Zuordnung",
            itemValue: null
        },
        {
            itemId: 66,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 16 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [36, 48],
            itemText: "plant mehrgliedrige bekannte Handlungen selbständig <em>schrittweise bei der Durchführung</em> (z.B. deckt den Tisch, holt sich den Teller, merkt was fehlt, holt den Löffel, merkt… etc.)",
            itemComment: "Handlungsplanung; überblickt noch nicht die vollständige Handlung",
            itemSuccessor: 67,
            itemValue: null
        },
        {
            itemId: 67,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 17 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [48, 60],
            itemText: "plant eine <em>mehrgliedrige bekannte</em> Handlung in <em>vertrauter</em> Umgebung <em>vollständig, bevor</em> er sie selbständig durchführt (z.B. deckt den Tisch mit täglich benutztem Geschirr vollständig, d.h. er kann vorher sagen, was er braucht) bzw. legt sich vorher alle benötigten Dinge zurecht",
            itemComment: "Handlungsplanung; überblickt die vollständige Handlungskette",
            itemSuccessor: 74,
            itemValue: null
        },
        {
            itemId: 68,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 18 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [48, 60],
            itemText: "spricht von großen Wünschen, Ängsten und Ärger so, als seien sie Realität, d.h. <em>verwechselt Vorstellung und Realität</em> noch <em>bei emotional sehr bedeutsamen Themen</em>",
            itemComment: "Trennung von Vorstellung und äußerer Realität ist überwiegend vorhanden",
            itemSuccessor: 73,
            itemValue: null
        },
        {
            itemId: 69,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 19 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [48, 60],
            itemText: "unterscheidet <em>klar zwischen belebt und unbelebt auf anschaulichem Niveau</em>, indem er ein Merkmal als Begründung nennt. Dieses kann zutreffen muss aber nicht. Fehleinschätzungen dürfen vorkommen bei Lieblingesdingen und Naturphänomenen (z.B. die Wolke ist lebendig, weil sie sich bewegt)",
            itemComment: "das animistische / anthropomorphe Denken tritt weitgehend zurück",
            itemSuccessor: 76,
            itemValue: null
        },
        {
            itemId: 70,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 20 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [48, 60],
            itemText: "<em>begründet</em> seine Einschätzung anhand eines <em>einzigen</em>, deutlich <em>sichtbaren</em> Merkmals (vernachlässigt alle anderen) (z.B. ein in vier Stücke geschnittener Apfel ist mehr als ein unzerteilter)",
            itemComment: "denkt zentriert und anschauungsgebunden, d.h. die Anschauung dient als Beurteilungsmaßstab, weil eine übergeordnete Bezugsgöße fehlt.",
            itemSuccessor: 75,
            itemValue: null
        },
        {
            itemId: 71,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 21 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "fragt <em>gezielt</em> nach <em>sachgerechten</em> Erklärungen für <em>nicht offensichtliche Zusammenhänge</em> (z.B. Warum ist der Vogel lebendig und die Feder nicht?; Wo war ich, bevor ich auf die Welt kam? Warum gibt es Krieg, wenn die Menschen doch Frieden wollen?) und merkt sie sich",
            itemComment: "will die Wirklichkeit rational erfassen; magisches und animistische Denken reduzieren sich",
            itemValue: null
        },
        {
            itemId: 72,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 22 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "<em>erklärt</em> bei <em>einfachen</em> vertrauten Handlungen seine <em>Strategie</em> und <em>merkt</em>, wenn sie nicht aufgeht (z.B. sagt, woran er sich orientiert, um zu beurteilen, ob ein Puzzleteil in eine Lücke passt; wie er sich verhalten muss, um bei seinem Gegenüber die gewünschte Wirkung zu erzielen)",
            itemComment: "Metakognition = Fähigkeit, eigene Denkmethoden und Problemlösestrategien zu beschreiben (\"Denken über das Denken\"); Teil der Selbstreflexion und der Fähigkeit, eigene Gedankenfehler zu erkennen und zu korrigieren",
            itemValue: null
        },
        {
            itemId: 73,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 23 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "<em>benutzt rationale Prüfstrategien</em>, um zu entscheiden, ob eine Vorstellung (Wunsch, Angst, Handlungsergebnis) nur in seinem Kopf wirklich ist oder von Anderen geteilt wird, <em>und sucht nach Gründen für die Abweichung</em> (z.B. überprüft durch Nachfragen, ob seine Angst, die Freundin trennt sich von ihm, Wirklichkeit ist); überprüft, ob sein Rechenergebnis stimmt, mit geeigneten Methoden",
            itemComment: "sichere Trennung zwischen Phantasie und Realität. Gute Realitätsprüfung und differenzierte Metakognition",
            itemValue: null
        },
        {
            itemId: 74,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 24 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "erarbeitet sich <em>neue</em> Handlungsabläufe selbstständig nach visueller <em>Vorlage</em> (z.B. Bauplan)",
            itemComment: "versteht symbolisierte Informationen und setzt sie in Handlung um",
            itemValue: null
        },
        {
            itemId: 75,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 25 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "berücksichtigt bei der Beurteilung eines Sachverhaltes das Zusammenwirken von zwei bis drei Merkmalen und begründet das Ergebnis korrekt (z.B. ein in vier Stücke geschnittener Apfel ist genau so viel wie der ganze, wenn man ihn wieder zusammensetzt und kein Stück weggenommen hat; es ist Sommer, weil die Laubbäume grün und die Tage länger sind als die Nacht)",
            itemComment: "Dezentriertes Denken. Mengenkonstanz = Mengen bleiben trotz Veränderung der äußeren Form gleich; Teil des Invarianzprinzips",
            itemValue: null
        },
        {
            itemId: 76,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 26 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "teilt die kulturell übliche Vorstellung von Realität, d.h. versteht animistische, analoge oder magische Vorstellungen korrekt als bildhafte Vergleiche oder Redensarten (z.B. weiß: lebendig ist nur ein Wesen, das atmet)",
            itemComment: "denkt rational, d.h. hat die Denkformen des symbolisch-anschaulichen Denkens überwunden",
            itemValue: null
        },
        {
            itemId: 77,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 27 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "beherrscht die <em>vier Grundrechenarten</em> und wendet sie <em>selbständig</em> an (z.B. beim Umgang mit Geld)",
            itemComment: "beherrscht den Zahlbegriff und das Prinzip der Reihenbildung = Erfassen des einer Reihe zugrunde liegenden Gesetzes, lernt dieses zur Vereinfachung seiner Handlungen zu benutzen",
            itemValue: null
        },
        {
            itemId: 78,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 28 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [144, 216],
            itemText: "versteht <em>komplexe theoretische</em> Zusammenhänge und gibt sie korrekt wieder (z.B. schwierige philosophische Texte, mathematische oder naturwissenschaftliche Gesetze)",
            itemComment: "Aspekt des formal-logischen Denkens",
            itemValue: null
        },
        {
            itemId: 79,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 1 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [8, 12],
            itemText: "äußert Wünsche und Abneigungen durch klare <em>präverbale</em> Laute (z.B. \"äh\") nicht nur durch Schreien",
            itemComment: "eindeutige Ruflaute",
            itemSuccessor: 80,
            itemValue: null
        },
        {
            itemId: 80,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 2 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [12, 18],
            itemText: "drückt Absichten aus durch <em>Zeigen</em>, An-der-Hand-Ziehen, <em>einzelne</em> Wörter",
            itemComment: "Ein-Wort-Sätze",
            itemSuccessor: 81,
            itemValue: null
        },
        {
            itemId: 81,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 3 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [18, 24],
            itemText: "spricht in Zwei-Wort-Sätzen (z.B. Ball haben, Ball weg, da Ball), Wörter bilden eine Sineinheit",
            itemComment: "Symbolfunktion, hat kommunikative Funktion der Sprache erfasst",
            itemSuccessor: 84,
            itemValue: null
        },
        {
            itemId: 82,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 4 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [24, 30],
            itemText: "verhält sich im Gespräch egozentrisch, d.h. erzählt <em>kontextunabhängig</em>, was ihm einfällt",
            itemComment: "egozentrisches Weltbild; fehlende Impulskontrolle",
            itemSuccessor: 87,
            itemValue: null
        },
        {
            itemId: 83,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 5 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [24, 30],
            itemText: "<em>teilt die Welt</em> in <em>grobe</em> Strukturen ein; \"Schwarz-Weiß-Denken\" (groß / klein, viel / wenig, lieb / böse, schwer / leicht, immer / nie)",
            itemComment: "erstes undifferenziertes gedankliches Ordnungsprinzip (Kategorisierung),bildet sprachliche Gegensätze, kein Verständnis für Zwischentöne",
            itemSuccessor: 89,
            itemValue: null
        },
        {
            itemId: 84,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 6 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [30, 36],
            itemText: "spricht in einfachen Mehrwortsätzen bis zu sechs Wörtern (Fehler sind typisch)",
            itemComment: "",
            itemSuccessor: 86,
            itemValue: null
        },
        {
            itemId: 85,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 7 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [30, 36],
            itemText: "stellt mit deutlichem <em>Interesse an der Antwort W-Fragen</em> (wo, wann, wer, warum)",
            itemComment: "Erkenntnisinteresse",
            itemValue: null
        },
        {
            itemId: 86,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 8 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [36, 48],
            itemText: "bildet einfache <em>Satzgefüge</em> (Haupt- und Nebensatz), die <em>leichte grammatische Fehler</em> (z.B. bei der Pluralbildung / Zeitform) enthalten können",
            itemComment: "",
            itemSuccessor: 90,
            itemValue: null
        },
        {
            itemId: 87,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 9 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [36, 48],
            itemText: "bezieht sich durch Fragen und Antworten im Gespräch auf das Gegenüber, <em>braucht</em> aber die <em>Steuerung</em> durch die Bezugsperson",
            itemComment: "",
            itemSuccessor: 91,
            itemValue: null
        },
        {
            itemId: 88,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 10 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "spricht <em>bereitwillig und geordnet</em> über seine <em>Befindlichkeit</em> und begründet sie (z.B. ist ärgerlich, traurig, freut sich, weil…, hat Angst vor…, weil)",
            itemComment: "Zeichen für beginnende Selbstreflexion",
            itemValue: null
        },
        {
            itemId: 89,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 11 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "<em>differenziert</em> Qualitäten und Quantitäten (z.B. immer – oft - manchmal – nie; viel – etwas – nichts; sehr…), d.h. macht Abstufungen",
            itemComment: "das \" Schwarz - Weiß - Denken\" ist überwunden",
            itemValue: null
        },
        {
            itemId: 90,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 12 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "gebraucht die Umgangssprache korrekt (Satzbau mit Nebensätzen, Wortendungen, Aussprache, Redefluss)",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 91,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 13 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "beherrscht das <em>dialogische</em> Gespräch ohne die Steuerung durch die Bezugsperson, d.h. erzählt, hört zu, bleibt beim Thema, antworetet auf Fragen angemessen",
            itemComment: "überwindet das egozentrische Denken; Zeichen von Impulskontrolle und Konzentrationsfähigkeit",
            itemValue: null
        },
        {
            itemId: 92,
            itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 1 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [0, 4],
            itemText: "beruhigt sich durch freundlichen Stimmklang oder Musik",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 93,
            itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 2 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [4, 8],
            itemText: "erfasst den <em>emotionalen</em> Sinn des Gesagten über die Mimik oder den Sprachklang",
            itemComment: "",
            itemSuccessor: 94,
            itemValue: null
        },
        {
            itemId: 94,
            itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 3 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [12, 18],
            itemText: "versteht Äußerungen, die sich auf die <em>gegenwärtige</em> Situation beziehen",
            itemComment: "",
            itemSuccessor: 96,
            itemValue: null
        },
        {
            itemId: 95,
            itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 4 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [18, 24],
            itemText: "erkennt Dinge auf Abbildungen, d.h. sucht oder zeigt auf Befragen bekannte Gegenstände (z.B. in Bilderbüchern, auf Fotos, beim Fernsehen)",
            itemComment: "Teil der Symbolfunktion; wichtig für die Begriffsbildung",
            itemValue: null
        },
        {
            itemId: 96,
            itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 5 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [30, 36],
            itemText: "versteht viele sprachliche Inhalte, die sich auf das <em>vertraute Alltagsleben</em> beziehen, d.h. Aussagen über nicht Gegenwärtiges, das aber zu seinem gewohnten Alltagsablauf gehört",
            itemComment: "",
            itemSuccessor: 97,
            itemValue: null
        },
        {
            itemId: 97,
            itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 6 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [36, 48],
            itemText: "versteht Erklärungen, die aus der <em>eigenen Erfahrung</em> nachvollziehbar sind",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 98,
            itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 7 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "hört gern vorgelesene oder erzählte Geschichten, Hörbücher / Hörspiele <em>ohne</em> Anschauungsmaterial <em>und</em> versteht den Sinn, d.h. kann Fragen beantworten",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 99,
            itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 8 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "befolgt <em>drei</em> einfache, <em>voneinander unabhängige, gleichzeitig gegebene</em> Aufforderungen (z.B. bring den Teller in die Küche, hole das Eis aus dem Kühlschrank und bring einen großen Löffel aus der Schublade mit)",
            itemComment: "gute Merkfähigkeit",
            itemValue: null
        },
        {
            itemId: 100,
            itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 9 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "versteht <em>als Zuhörer</em> auf umgangssprachlichem Niveau geführte Gespräche / Berichte über alle gängigen Themen (z.B. Alltagsfragen, Urlaub, Sport, Arbeit), d.h. zeigt Interesse, macht passende Kommentare, bezieht sich evtl. zu einem späteren Zeitpunkt auf zentrale Inhalte",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 101,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 1 },
            itemType: "n",
            itemAge: [0, 4],
            itemText: "erkennt die Stimme der Mutter / Bezugsperson und reagiert auf sie (z.B. dreht den Kopf in ihre Richtung)",
            itemComment: "akustische Wahrnehmung / sensomotorische Intelligenz: modale Wahrnehmung",
            itemValue: null
        },
        {
            itemId: 102,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 2 },
            itemType: "n",
            itemAge: [0, 4],
            itemText: "erkennt Gerüche (z.B. Kaffee, Tomatensauce, den Geruch der Mutter / Bezugsperson) ",
            itemComment: "olfaktorische Wahrnehmung / sensomotorische Intelligenz: modale Wahrnehmung",
            itemValue: null
        },
        {
            itemId: 103,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 3 },
            itemType: "n",
            itemAge: [0, 4],
            itemText: "betrachtet Dinge / Menschen in seinem Blickfeld mit koordinierten Augenbewegungen (z.B. fixiert ein bewegtes Gesicht, folgt mit den Augen sich bewegenden Gegenständen)",
            itemComment: "visuelle Wahrnehmung / sensomotorischze Intelligenz: modale Wahrnehmung",
            itemValue: null
        },
        {
            itemId: 104,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 4 },
            itemType: "n",
            itemAge: [0, 4],
            itemText: "erspürt Gegenstände mit dem Mund (z.B. berührt sie mit den Lippen, leckt sie ab, beißt) oder den Händen (z.B. betastet sie)",
            itemComment: "taktile Wahrnehmung / sensomotorische Intelligenz: modale Wahrnehmung",
            itemValue: null
        },
        {
            itemId: 105,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 5 },
            itemType: "i",
            itemAge: [4, 8],
            itemText: "verbindet mit dem Auftauchen bestimmter Personen oder Gegenstände bestimmte Ereignisse (z.B. Erregung beim Anblick der Trinkflasche)",
            itemComment: "erkennt Funktionssignale, Wahrnehmung des Wenn-Dann -Zusammenhangs als Grundlage der Erwartungshaltung  / beginnende Objektpermanenz",
            itemSuccessor: 107,
            itemValue: null
        },
        {
            itemId: 106,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 6 },
            itemType: "n",
            itemAge: [8, 12],
            itemText: "unterscheidet klar zwischen vertrauten und fremden Menschen und Situationen, erkennbar an Mimik und Verhalten",
            itemComment: "Objektpermanenz",
            itemValue: null
        },
        {
            itemId: 107,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 7 },
            itemType: "n",
            itemAge: [12, 18],
            itemText: "zeigt deutliche Erwartungshaltung (z.B. fordert Bezugspersonen zur Wiederholung von Spielen / Handlungen auf)",
            itemComment: "Erfahrung ermöglicht Erwartung und wird zur Orientierung an der Bezugsperson und Handlungsmotivation",
            itemValue: null
        },
        {
            itemId: 108,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 8 },
            itemType: "n",
            itemAge: [12, 18],
            itemText: "nimmt mit einem Gegenstand die Berührungsfläche wahr (z.B. beim Kritzeln  mit einem Stift ein Blatt Papier, mit einem Löffel den Boden des Tellers)",
            itemComment: "taktile Kontrolle",
            itemValue: null
        },
        {
            itemId: 109,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 9 },
            itemType: "n",
            itemAge: [18, 24],
            itemText: "erkennt Veränderungen beim Wetter, d.h. Sonne, Regen, Wind und Schnee (z.B. freut sich, wenn es schneit)",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 110,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 10 },
            itemType: "n",
            itemAge: [18, 24],
            itemText: "erkennt sich im Spiegel (z.B. nennt sich beim Namen)",
            itemComment: "Zeichen für Ich-Bewusstsein, Selbstwahrnehmung über ein Medium Übergang zu symbolischem Denken",
            itemValue: null
        },
        {
            itemId: 111,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 11 },
            itemType: "n",
            itemAge: [24, 30],
            itemText: "ordnet Grundfarben zu (z.B. ein roter Gegenstand zu einem anderen roten)",
            itemComment: "Wahrnehmung der Gemeinsamkeit eines Merkmals als Grundlage der Klassenbildung",
            itemValue: null
        },
        {
            itemId: 112,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 12 },
            itemType: "n",
            itemAge: [30, 36],
            itemText: "erkennt bekannte Personen und häufig besuchte Orte auch bei deutlicher äußerer Veränderung wieder",
            itemComment: "letzte Stufe der Objektpermanenz / stabile innere Bilder von vertrauten Objekten als Teil des symbolischen Denkens",
            itemValue: null
        },
        {
            itemId: 113,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 13 },
            itemType: "i",
            itemAge: [30, 36],
            itemText: "erkennt alltagsrelevante Piktogramme",
            itemComment: " symbolisches Denken",
            itemSuccessor: 122,
            itemValue: null
        },
        {
            itemId: 114,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 14 },
            itemType: "i",
            itemAge: [30, 36],
            itemText: "lokalisiert Schmerzreize ansatzweise (Kopf, Bauch, Arm, Bein/Fuß)",
            itemComment: "Selbstwahrnehmung: Körperschema und Körperbegriff",
            itemSuccessor: 119,
            itemValue: null
        },
        {
            itemId: 115,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 15 },
            itemType: "n",
            itemAge: [36, 48],
            itemText: "erkennt Gegenstände, ohne sie zu sehen, nur durch Betasten wieder (z.B. im Dunkeln, unter einer Decke)",
            itemComment: "taktile Wahrnehmung /stabile innere Bilder",
            itemValue: null
        },
        {
            itemId: 116,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 16 },
            itemType: "n",
            itemAge: [36, 48],
            itemText: "sortiert fünf Gegenstände durch Versuch und Irrtum der Größe nach",
            itemComment: "Wahrnehmung der Größenverhältnisse; Beginn der Reihenbildung (Grundlage für Mengen- und Zahlbegriff)",
            itemValue: null
        },
        {
            itemId: 117,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 17 },
            itemType: "i",
            itemAge: [36, 48],
            itemText: "erfasst optisch die Menge drei",
            itemComment: "visuelle Wahrnehmung und Grundlage für den Beginn des Zählens  (Bewusstsein für Mengen)",
            itemSuccessor: 121,
            itemValue: null
        },
        {
            itemId: 118,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 18 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "setzt nach Aufforderung verschiedene stimmliche Qualitäten situationsgerecht ein (z.B. flüstern, laut / leise sprechen, hohe / tiefe Stimme) und hält sich etwa fünf Minuten daran (z.B. um Rücksicht zu nehmen, in der Gruppe laut zu sprechen)",
            itemComment: "Zeichen der Selbstwahrnehmung und Selbststeuerung, unterscheidet Qualitäten",
            itemValue: null
        },
        {
            itemId: 119,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 19 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "lokalisiert Schmerzen genau (z.B. Ohren, Hals, Rücken)",
            itemComment: "Selbstwahrnehmung,differenziertes  Körperschema",
            itemValue: null
        },
        {
            itemId: 120,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 20 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "versteht Symbole als Bedeutungsträger (z.B. Verkehrszeichen, Buchstaben und Ziffern)",
            itemComment: "anschauliches Denken: Verständnis für erlernte abstrakte Symbole",
            itemValue: null
        },
        {
            itemId: 121,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 21 },
            itemType: "i",
            itemAge: [60, 72],
            itemText: "addiert und subtrahiert im Zahlraum bis 10, Finger dienen als Hilfe",
            itemComment: "Übergang vom Zählen zum anschaulichen Rechnen",
            itemSuccessor: 125,
            itemValue: null
        },
        {
            itemId: 122,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 22 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "liest sinnerfassend",
            itemComment: "seriale Wahrnehmung, erfasst den Zusammenhang von Wörtern und Inhalt",
            itemValue: null
        },
        {
            itemId: 123,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 23 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "unterscheidet rechts und links bei sich und Anderen",
            itemComment: "Körperschema",
            itemValue: null
        },
        {
            itemId: 124,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 24 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "setzt feine Reizqualitäten (optisch, akustisch, taktil) differenziert ein (z.B. variable Sprechgeschwindigkeit, Lautstärke, Tonhöhe, Rhythmen; Druckqualität beim Streicheln, Schneiden, Schreiben; Farbnuancen beim Malen etc.)",
            itemComment: "differenzierte Selbstwahrnehmung und Steuerung des Selbstausdrucks",
            itemValue: null
        },
        {
            itemId: 125,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 25 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "addiert und subtrahiert im Zahlenraum bis 100",
            itemComment: "erfasst das Dezimalsystem als gedankliche Struktur",
            itemValue: null
        },
        {
            itemId: 126,
            itemCategory: { categoryAbbrv: "W", itemCategoryId: 26 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "sortiert Gegenstände systematisch und berücksichtigt dabei mehrere Kriterien (z.B. Größe, Form, Farbe)",
            itemComment: "Klassenbildung",
            itemValue: null
        },
        {
            itemId: 127,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 1 },
            itemType: "i",
            itemAge: [0, 4],
            itemText: "stützt sich in Bauchlage sicher auf die Unterarme, schaut in alle Richtungen nach Personen und Gegenständen",
            itemComment: "Visuelle Orientierung. Voraussetzung für die räumliche und persongebundene Orientierung im näheren Umfeld; hat Kopfkontrolle",
            itemSuccessor: 130,
            itemValue: null
        },
        {
            itemId: 128,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 2 },
            itemType: "n",
            itemAge: [4, 8],
            itemText: "lauscht bei Geräuschen und Stimmen und sucht die Geräuschquelle durch Kofbewegungen",
            itemComment: "auditive Orientierung; Voraussetzung für die Orientierung im Raum und an der Bezugsperson",
            itemValue: null
        },
        {
            itemId: 129,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 3 },
            itemType: "n",
            itemAge: [8, 12],
            itemText: "nimmt Dinge aus einem Behälter und legt sie wieder hinein",
            itemComment: "erste Erfahrungen mit räumlichen Bezügen; Experimentieren mit Objektpermanenz",
            itemValue: null
        },
        {
            itemId: 130,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 4 },
            itemType: "i",
            itemAge: [12, 18],
            itemText: "erkundet die Räume seiner Wohnung (z.B. öffnet Schubladen und Türen, folgt Bezugspersonen in andere Räume)",
            itemComment: "räumliche Orientierung; Voraussetzung für  die Orientierung im Raum und an Bezugspersonen; Objektpermanenz",
            itemSuccessor: 131,
            itemValue: null
        },
        {
            itemId: 131,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 5 },
            itemType: "i",
            itemAge: [18, 24],
            itemText: "kann sich sicher in der Wohnung und an vertrauten Orten außerhalb der Wohnung (z.B. Hausgrundstück, Spielplatz)  orientieren",
            itemComment: "räumliche Orientierung",
            itemSuccessor: 138,
            itemValue: null
        },
        {
            itemId: 132,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 6 },
            itemType: "n",
            itemAge: [24, 30],
            itemText: "kennt die Reihenfolge von gewohnten Abläufen und orientiert sich an ihnen (z.B. Hände waschen vor dem Essen)",
            itemComment: "anfängliche Zeitorientierung: Lernen am Modell und Aufbau von Gewohnheiten; seriale Wahrnehmung",
            itemValue: null
        },
        {
            itemId: 133,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 7 },
            itemType: "n",
            itemAge: [30, 36],
            itemText: "spricht von Vergangenem und Zukünftigem",
            itemComment: "zeitliche Orientierung",
            itemValue: null
        },
        {
            itemId: 134,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 8 },
            itemType: "i",
            itemAge: [36, 48],
            itemText: "beachtet bekannte Fußgängerampeln",
            itemComment: "Beginn der Verkehrssicherheit",
            itemSuccessor: 139,
            itemValue: null
        },
        {
            itemId: 135,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 9 },
            itemType: "n",
            itemAge: [36, 48],
            itemText: "kennt sein eigenes Alter",
            itemComment: "Beginn des Zählens",
            itemValue: null
        },
        {
            itemId: 136,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 10 },
            itemType: "n",
            itemAge: [36, 48],
            itemText: "erfasst die geläufigen einfachen zeitlichen und räumlichen Bezüge und benennt sie (z.B. \"auf, unter, neben, hinter, oben, unten, innen, außen; jetzt, später, früher, heute, gestern, morgen\")",
            itemComment: "sprachlicher Ausdruck der räumlichen und zeitlichen Orientierung",
            itemValue: null
        },
        {
            itemId: 137,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 11 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "verbindet Jahreszeiten mit typischen Merkmalen (z.B. gelbe Blätter im Herbst)",
            itemComment: "anschauliches Denken",
            itemValue: null
        },
        {
            itemId: 138,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 12 },
            itemType: "i",
            itemAge: [48, 60],
            itemText: "findet sich in der bekannten Umgebung zurecht, kennt den Weg zu vertrauten Orten in der Nähe (z.B. Kindergarten, Werkstatt, Wohnung von Freunden)",
            itemComment: "räumliche Orientierung",
            itemSuccessor: 142,
            itemValue: null
        },
        {
            itemId: 139,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 13 },
            itemType: "i",
            itemAge: [60, 72],
            itemText: "achtet als Fußgänger auf den Straßenverkehr, wenn er nicht abgelenkt ist",
            itemComment: "ist teilweise verkehrssicher",
            itemSuccessor: 141,
            itemValue: null
        },
        {
            itemId: 140,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 14 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "wendet Namen von Wochentagen und Jahreszeiten korrekt an",
            itemComment: "Verständnis für zeitliche Strukturen",
            itemValue: null
        },
        {
            itemId: 141,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 15 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "ist als Fußgänger verkehrssicher",
            itemComment: "Verkehrssicherheit vorhanden",
            itemValue: null
        },
        {
            itemId: 142,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 16 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "kann sich im erweiterten Umfeld (z.B. Dorf, Stadtteil) sicher orientieren",
            itemComment: "räumliche Orientierung in der Öfffentlichkeit",
            itemValue: null
        },
        {
            itemId: 143,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 17 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "liest die Uhr (analog und digital) und den Kalender",
            itemComment: "Bewusstsein für die exakte Zeiteinteilung",
            itemValue: null
        },
        {
            itemId: 144,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 18 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "fährt vertraute Strecken mit öffentlichen Nahverkehrsmitteln",
            itemComment: "Orientierung in der Öffentlichkeit",
            itemValue: null
        },
        {
            itemId: 145,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 19 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "ist mit dem Fahrrad verkehrssicher",
            itemComment: "ist verkehrssicher",
            itemValue: null
        },
        {
            itemId: 146,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 20 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "hält kurzfristige oder regelmäßige wiederkehrende zeitliche und örtliche Verabredungen ein",
            itemComment: "Selbstkontrolle im Umgang mit Raum und Zeit",
            itemValue: null
        },
        {
            itemId: 147,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 21 },
            itemType: "n",
            itemAge: [144, 216],
            itemText: "teilt sich seine Zeit selbst zweckmäßig ein, um den Erfordernissen des Alltags gerecht zu werden",
            itemComment: "Zeitmanagement",
            itemValue: null
        },
        {
            itemId: 148,
            itemCategory: { categoryAbbrv: "RZÖ", itemCategoryId: 22 },
            itemType: "n",
            itemAge: [144, 216],
            itemText: "sucht selbstständig nicht alltägliche Orte und Institutionen auf (Schwimmbad, Bücherei, Museum, Konzerthalle) und benutzt dafür ggf.verschiedene öffentliche Verkehrsmittel (z.B. Bus / S-Bahn)",
            itemComment: "Planungsfähigkeit",
            itemValue: null
        },
        {
            itemId: 149,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 1 },
            itemType: "i",
            itemAge: [0, 4],
            itemText: "saugt aus der Flasche oder an Körperteilen (z.B. Finger)",
            itemComment: "",
            itemSuccessor: 151,
            itemValue: null
        },
        {
            itemId: 150,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 2 },
            itemType: "i",
            itemAge: [4, 8],
            itemText: "isst Brei vom angebotenen Löffel",
            itemComment: "",
            itemSuccessor: 155,
            itemValue: null
        },
        {
            itemId: 151,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 3 },
            itemType: "i",
            itemAge: [8, 12],
            itemText: "trinkt aus einem Becher, wenn er gehalten wird, ohne zu kleckern",
            itemComment: "",
            itemSuccessor: 154,
            itemValue: null
        },
        {
            itemId: 152,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 4 },
            itemType: "n",
            itemAge: [8, 12],
            itemText: "kaut feste Nahrung (z.B. Brot, Obststücke)",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 153,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 5 },
            itemType: "n",
            itemAge: [12, 18],
            itemText: "führt einen einfachen Auftrag aus (z.B. hol den Teller)",
            itemComment: "situatives Sprachverständnis",
            itemValue: null
        },
        {
            itemId: 154,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 6 },
            itemType: "n",
            itemAge: [12, 18],
            itemText: "trinkt allein aus einer Tasse bzw. einem Becher",
            itemComment: "Verständnis für Alltagswerkzeug",
            itemValue: null
        },
        {
            itemId: 155,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 7 },
            itemType: "i",
            itemAge: [12, 18],
            itemText: "isst selbst mit dem Löffel, kleckert dabei noch",
            itemComment: "Werkzeuggebrauch",
            itemSuccessor: 159,
            itemValue: null
        },
        {
            itemId: 156,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 8 },
            itemType: "n",
            itemAge: [18, 24],
            itemText: "benutzt vertraute Dinge funktionsgerecht und entsprechend der sozialen Bedeutung (z.B. Tasse zum Trinken, Puppe zum Liebhaben)",
            itemComment: "Beginn der funktionalen Handlungsplanung",
            itemValue: null
        },
        {
            itemId: 157,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 9 },
            itemType: "i",
            itemAge: [24, 30],
            itemText: "gießt sich ein Getränk ein, aber mit Schwierigkeiten (z.B. kleckert, verkennt die Füllhöhe)",
            itemComment: "",
            itemSuccessor: 164,
            itemValue: null
        },
        {
            itemId: 158,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 10 },
            itemType: "i",
            itemAge: [24, 30],
            itemText: "benutzt zum Essen eine Gabel, hält sie im Faustgriff",
            itemComment: "taktile Kontrolle; Werkzeuggebrauch",
            itemSuccessor: 164,
            itemValue: null
        },
        {
            itemId: 159,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 11 },
            itemType: "i",
            itemAge: [24, 30],
            itemText: "isst mit dem Löffel ohne zu kleckern, hält ihn im Faustgriff",
            itemComment: "",
            itemSuccessor: 164,
            itemValue: null
        },
        {
            itemId: 160,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 12 },
            itemType: "n",
            itemAge: [30, 36],
            itemText: "bedient ein einfaches elektrisches Gerät (z.B. CD-Spieler, Radio, Tablet)",
            itemComment: "Verständnis für funktionale Zusammenhänge ",
            itemValue: null
        },
        {
            itemId: 161,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 13 },
            itemType: "i",
            itemAge: [36, 48],
            itemText: "benutzt ein Messer zum Schneiden und Streichen mit Schwierigkeiten (z.B. Kraftdosierung)",
            itemComment: "taktile Kontrolle",
            itemSuccessor: 164,
            itemValue: null
        },
        {
            itemId: 162,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 14 },
            itemType: "i",
            itemAge: [48, 60],
            itemText: "kennt die Funktion des Geldes, aber nicht den Wert von Münzen und Scheinen",
            itemComment: "anschauungsgebundenes Denken",
            itemSuccessor: 167,
            itemValue: null
        },
        {
            itemId: 163,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 15 },
            itemType: "i",
            itemAge: [48, 60],
            itemText: "führt einfache gewohnte Haushaltstätigkeiten selbstständig durch (Tisch decken, abtrocknen, Spülmaschine ausräumen)",
            itemComment: "Handlungsplanung",
            itemSuccessor: 165,
            itemValue: null
        },
        {
            itemId: 164,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 16 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "handhabt Besteck und Geschirr motorisch sicher (geht korrekt mit Löffel, Messer und Gabel um, gießt sich ein Getränk ein)",
            itemComment: "gute taktile Kontrolle",
            itemValue: null
        },
        {
            itemId: 165,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 17 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "hilft nach Aufforderung selbständig und sachgerecht im Haushalt (z.B. beim Kochen, Staub putzen, aufräumen)",
            itemComment: "Leistungsmotivation und Pflichtbewusstsein",
            itemValue: null
        },
        {
            itemId: 166,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 18 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "beherrscht die Tischsitten  (z.B. isst mit Messer und Gabel, kaut mit geschlossenem Mund)",
            itemComment: "Orientierung an kulturellen Normen",
            itemValue: null
        },
        {
            itemId: 167,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 19 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "kennt den Wert von Geldmünzen und -scheinen, d.h. weiß dass 1€ 10x10ct. oder 5x20ct sind",
            itemComment: "Zahlverständnis, kann addieren und subtrahieren",
            itemValue: null
        },
        {
            itemId: 168,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 20 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "erledigt kleinere Einkäufe allein, weiß ungefähr, was er für sein Geld bekommt",
            itemComment: "Zahl- und Mengenverständnis",
            itemValue: null
        },
        {
            itemId: 169,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 21 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "benutzt ohne Hilfestellung sachgerecht anspruchsvolle Messgeräte (z.B. Metermaß, Waage, Messbecher)",
            itemComment: "Kombination von Sachwissen und Geschicklichkeit; Verständnis für Messwerkzeuge und Maßeinheiten",
            itemValue: null
        },
        {
            itemId: 170,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 22 },
            itemType: "i",
            itemAge: [96, 144],
            itemText: "bereitet einfache bekannte Gerichte frisch zu (Nudeln, Bratkartoffeln)",
            itemComment: "Handlungsplanung",
            itemSuccessor: 173,
            itemValue: null
        },
        {
            itemId: 171,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 23 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "schätzt Preisrelationen im Rahmen seiner Erfahrung ein",
            itemComment: "konkret-logisches Denken: Zahl- und Mengenbegriff",
            itemValue: null
        },
        {
            itemId: 172,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 24 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "achtet selbst auf seine Sachen (z.B. nimmt die Dinge mit, die er für unterwegs braucht, geht mit ihnen sorgfältig um, passt auf, dass er nichts verliert)",
            itemComment: "Selbstverantwortung",
            itemValue: null
        },
        {
            itemId: 173,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 25 },
            itemType: "n",
            itemAge: [144, 216],
            itemText: "kocht selbständig neue Gerichte nach Rezept",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 174,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 26 },
            itemType: "n",
            itemAge: [144, 216],
            itemText: "putzt sein Zimmer sachgerecht und vollständig (inkl. abstauben, wischen, Fenster putzen)",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 175,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 27 },
            itemType: "n",
            itemAge: [144, 216],
            itemText: "setzt sich selbständig mit neuen lebenspraktischen Problemen  auseinander (z.B. Praktikum, Anforderungen der Ausbildung)",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 176,
            itemCategory: { categoryAbbrv: "LP-EH", itemCategoryId: 28 },
            itemType: "n",
            itemAge: [216, 1188],
            itemText: "führt seinen Haushalt, verwaltet sein Geld, erledigt alle lebenspraktischen Aufgaben selbständig",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 177,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 1 },
            itemType: "i",
            itemAge: [12, 18],
            itemText: "hilft durch Körperbewegungen beim An- und Ausziehen (z.B. hebt die Arme hoch)",
            itemComment: "erstes Verständnis für Handlungsabläufe (serielles Denken, Erwartungshaltungen)",
            itemSuccessor: 178,
            itemValue: null
        },
        {
            itemId: 178,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 2 },
            itemType: "i",
            itemAge: [18, 24],
            itemText: "zieht sich einfache Kleidungsstücke aus (z.B. Schuhe, Socken, Hose, Mütze)",
            itemComment: "",
            itemSuccessor: 180,
            itemValue: null
        },
        {
            itemId: 179,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 3 },
            itemType: "i",
            itemAge: [24, 30],
            itemText: "benutzt mit Anleitung  die Toilette / den Topf",
            itemComment: "Orientierung an der Bezugsperson",
            itemSuccessor: 183,
            itemValue: null
        },
        {
            itemId: 180,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 4 },
            itemType: "i",
            itemAge: [24, 30],
            itemText: "zieht sich unter Anleitung aus",
            itemComment: "Orientierung an der Bezugsperson",
            itemSuccessor: 185,
            itemValue: null
        },
        {
            itemId: 181,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 5 },
            itemType: "i",
            itemAge: [24, 30],
            itemText: "öffnet große Knöpfe, Klettverschluss und Reißverschluss ",
            itemComment: "Hand-Hand-Koordination",
            itemSuccessor: 191,
            itemValue: null
        },
        {
            itemId: 182,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 6 },
            itemType: "i",
            itemAge: [24, 30],
            itemText: "wäscht sich flüchtig die Hände und trocknet sie ab",
            itemComment: "serielle Handlungen",
            itemSuccessor: 189,
            itemValue: null
        },
        {
            itemId: 183,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 7 },
            itemType: "i",
            itemAge: [30, 36],
            itemText: "meldet sich bei Harn- und Stuhldrang meist rechtzeitig, d.h. ist tagsüber überwiegend trocken und sauber",
            itemComment: "",
            itemSuccessor: 187,
            itemValue: null
        },
        {
            itemId: 184,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 8 },
            itemType: "i",
            itemAge: [30, 36],
            itemText: "zieht sich  verschiedene Kleidungsstücke an (z.B. Hose, Socken), ohne auf den korrekten Sitz zu achten",
            itemComment: "",
            itemSuccessor: 190,
            itemValue: null
        },
        {
            itemId: 185,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 9 },
            itemType: "n",
            itemAge: [36, 48],
            itemText: "zieht sich selbständig aus",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 186,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 10 },
            itemType: "n",
            itemAge: [36, 48],
            itemText: "ist in der Regel tagsüber und nachts trocken",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 187,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 11 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "erledigt die Toilettenhygiene zuverlässig, selbständig und vollständig, d.h. putzt sich ab, betätigt die Spülung, wäscht sich die Hände",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 188,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 12 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "putzt sich selbständig die Nase",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 189,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 13 },
            itemType: "i",
            itemAge: [48, 60],
            itemText: "wäscht sich mit Hilfestellung alle Körperteile",
            itemComment: "",
            itemSuccessor: 195,
            itemValue: null
        },
        {
            itemId: 190,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 14 },
            itemType: "i",
            itemAge: [48, 60],
            itemText: "zieht sich weitgehend selbständig an, braucht Hilfe bei der Beurteilung von vorn und hinten, rechts und links",
            itemComment: "",
            itemSuccessor: 194,
            itemValue: null
        },
        {
            itemId: 191,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 15 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "öffnet und schließt kleine Knöpfe",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 192,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 16 },
            itemType: "i",
            itemAge: [48, 60],
            itemText: "putzt sich die Zähne, allerdings nicht sachgerecht",
            itemComment: "",
            itemSuccessor: 195,
            itemValue: null
        },
        {
            itemId: 193,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 17 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "vermeidet es, sich fremden Menschen nackt zu zeigen (z.B. will auf der Toilette allein sein, will eien Badeanzug tragen )",
            itemComment: "hat Schamgefühl",
            itemValue: null
        },
        {
            itemId: 194,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 18 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "zieht sich selbständig und korrekt vollständig an",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 195,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 19 },
            itemType: "i",
            itemAge: [72, 96],
            itemText: "wäscht sich nach Aufforderung, inkl. Zähneputzen",
            itemComment: "",
            itemSuccessor: 197,
            itemValue: null
        },
        {
            itemId: 196,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 20 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "stellt seine Kleidung nach eigenen Geschmacksvorstellungen farblich zusammen, beachtet das Wetter und die Situation",
            itemComment: "Konkret-logisches Denken: Handlungsplanung unter Berücksichtigung verschiedener Voraussetzungen",
            itemValue: null
        },
        {
            itemId: 197,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 21 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "erledigt die gewohnte tägliche Körperpflege selbstständig (inkl. Zähneputzen)",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 198,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 22 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "achtet selbständig auf saubere Kleidung",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 199,
            itemCategory: { categoryAbbrv: "LP-K", itemCategoryId: 23 },
            itemType: "n",
            itemAge: [144, 216],
            itemText: "ist in der Körperpflege vollständig selbständig, d.h. entscheidet nach Bedarf (z.B. Nagelpflege, Monatshygiene)",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 200,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 1 },
            itemType: "i",
            itemAge: [0, 4],
            itemText: "strampelt mit Armen und Beinen ",
            itemComment: "Massebewegungen, anfangs reflexgesteuert",
            itemSuccessor: 205,
            itemValue: null
        },
        {
            itemId: 201,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 2 },
            itemType: "i",
            itemAge: [4, 8],
            itemText: "zieht sich aus der Rückenlage an den angebotenen Händen zum Sitzen hoch",
            itemComment: "",
            itemSuccessor: 203,
            itemValue: null
        },
        {
            itemId: 202,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 3 },
            itemType: "n",
            itemAge: [4, 8],
            itemText: "dreht sich aus der Bauch- in die Rückenlage (und umgekehrt)",
            itemComment: "Rumpfrotation",
            itemValue: null
        },
        {
            itemId: 203,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 4 },
            itemType: "n",
            itemAge: [8, 12],
            itemText: "richtet sich selbst auf, sitzt frei und sicher",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 204,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 5 },
            itemType: "i",
            itemAge: [8, 12],
            itemText: "zieht sich an den Möbeln selbständig zum Stand hoch",
            itemComment: "",
            itemSuccessor: 209,
            itemValue: null
        },
        {
            itemId: 205,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 6 },
            itemType: "i",
            itemAge: [8, 12],
            itemText: "robbt und / oder krabbelt",
            itemComment: "Eroberung des Raumes",
            itemSuccessor: 206,
            itemValue: null
        },
        {
            itemId: 206,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 7 },
            itemType: "i",
            itemAge: [12, 18],
            itemText: "geht mindestens 3 Schritte frei",
            itemComment: "Eroberung des Raumes, Gleichgewicht",
            itemSuccessor: 212,
            itemValue: null
        },
        {
            itemId: 207,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 8 },
            itemType: "n",
            itemAge: [12, 18],
            itemText: "schiebt einen rollenden Gegenstand (z.B. Kinderwagen, Einkaufswagen) vor sich her",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 208,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 9 },
            itemType: "i",
            itemAge: [12, 18],
            itemText: "krabbelt Treppenstufen nach oben",
            itemComment: "",
            itemSuccessor: 210,
            itemValue: null
        },
        {
            itemId: 209,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 10 },
            itemType: "n",
            itemAge: [12, 18],
            itemText: "steht ohne Hilfe vom Boden / aus der Hocke auf",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 210,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 11 },
            itemType: "i",
            itemAge: [18, 24],
            itemText: "geht eine Treppe im Nachstellschritt hinauf und hinunter, hält sich mit mindestens einer Hand fest",
            itemComment: "",
            itemSuccessor: 218,
            itemValue: null
        },
        {
            itemId: 211,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 12 },
            itemType: "n",
            itemAge: [18, 24],
            itemText: "stößt einen Ball mit dem Fuß weg",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 212,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 13 },
            itemType: "n",
            itemAge: [18, 24],
            itemText: "geht sicher, variiert sein Tempo, rennt manchmal",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 213,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 14 },
            itemType: "n",
            itemAge: [24, 30],
            itemText: "hüpft mit beiden Füßen vorwärts ohne hinzufallen",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 214,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 15 },
            itemType: "i",
            itemAge: [30, 36],
            itemText: "fährt Dreirad oder ähnl., tritt dabei in die Pedale",
            itemComment: "",
            itemSuccessor: 219,
            itemValue: null
        },
        {
            itemId: 215,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 16 },
            itemType: "n",
            itemAge: [30, 36],
            itemText: "wirft einen Ball gezielt",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 216,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 17 },
            itemType: "n",
            itemAge: [36, 48],
            itemText: "klettert auf Klettergerüste und Bäume",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 217,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 18 },
            itemType: "n",
            itemAge: [36, 48],
            itemText: "fängt einen großen Ball in Brusthöhe mit beiden Händen sicher",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 218,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 19 },
            itemType: "n",
            itemAge: [36, 48],
            itemText: "geht eine Treppe  hinauf und hinunter, ohne sich festzuhalten",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 219,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 20 },
            itemType: "i",
            itemAge: [48, 60],
            itemText: "fährt Fahrrad mit Stützrädern",
            itemComment: "",
            itemSuccessor: 224,
            itemValue: null
        },
        {
            itemId: 220,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 21 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "steht einige Sekunden sicher auf einem Bein",
            itemComment: "Gleichgewicht",
            itemValue: null
        },
        {
            itemId: 221,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 22 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "geht rückwärts",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 222,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 23 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "beschäftigt sich gern mit Geräten, die eine gute Bewegungskoordination erfordern (Seilhüpfen, Rollschuhe, Inliner, Ski, Schlittschuhe)",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 223,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 24 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "schwimmt",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 224,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 25 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "fährt Fahrrad ohne Stützräder",
            itemComment: "gutes Gleichgewicht",
            itemValue: null
        },
        {
            itemId: 225,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 26 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "klettert auf oder über Hindernisse in Brusthöhe (Zäune, Mauern)",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 226,
            itemCategory: { categoryAbbrv: "GM", itemCategoryId: 27 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "spielt Ballspiele in der Gruppe (z.B. Fußball, Basketball), die eine präzise und zügige Reaktion verlangen",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 227,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 1 },
            itemType: "i",
            itemAge: [0, 4],
            itemText: "greift bei der Berührung der Handinnenfläche reflexhaft zu",
            itemComment: "Greifreflex",
            itemSuccessor: 229,
            itemValue: null
        },
        {
            itemId: 228,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 2 },
            itemType: "n",
            itemAge: [4, 8],
            itemText: "steckt Gegenstände in den Mund",
            itemComment: "beherrscht die Hand-Mund-Koordination / Beginn der sekundären Kreisreaktion ",
            itemValue: null
        },
        {
            itemId: 229,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 3 },
            itemType: "i",
            itemAge: [4, 8],
            itemText: "greift gezielt mit der ganzen Handfläche und gestrecktem  Daumen nach einem Gegenstand",
            itemComment: "palmares Greifen; Greifreflex ist überwunden / sekundäre Kreisreaktion",
            itemSuccessor: 233,
            itemValue: null
        },
        {
            itemId: 230,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 4 },
            itemType: "n",
            itemAge: [4, 8],
            itemText: "hält größere Gegenstände mit beiden Händen",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 231,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 5 },
            itemType: "n",
            itemAge: [4, 8],
            itemText: "wechselt einen Gegenstand zwischen den Händen",
            itemComment: "kann die Hand willentlich öffnen / sekundäre Kreisreaktion",
            itemValue: null
        },
        {
            itemId: 232,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 6 },
            itemType: "i",
            itemAge: [8, 12],
            itemText: "klopft zwei kleine Gegenstände (z.B. Würfel) aneinander",
            itemComment: "Beginn der Hand-Hand-Kooperation",
            itemSuccessor: 235,
            itemValue: null
        },
        {
            itemId: 233,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 7 },
            itemType: "n",
            itemAge: [8, 12],
            itemText: "ergreift schmale Gegenstände mit gestrecktem Zeigefinger und gestrecktem Daumen und / oder mit gebeugtem Zeigefinger und gebeugtem Daumen",
            itemComment: "beherrscht den Pinzetten- bzw. den Zangengriff",
            itemValue: null
        },
        {
            itemId: 234,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 8 },
            itemType: "n",
            itemAge: [12, 18],
            itemText: "drückt auf Knöpfe, um etwas zu aktivieren (z. B. Lichtschalter, Radio, Fahrstuhl) ",
            itemComment: "spielerisches Experimentieren",
            itemValue: null
        },
        {
            itemId: 235,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 9 },
            itemType: "n",
            itemAge: [12, 18],
            itemText: "koordiniert beide Hände bei einer Tätigkeit (z.B. trägt einen Gegenstand mit beiden Händen oder eine Hand hält, die andere ist aktiv)",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 236,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 10 },
            itemType: "i",
            itemAge: [12, 18],
            itemText: "kritzelt mit einem Stift (Faustgriff)",
            itemComment: "taktile Kontrolle / spielerisches Experimentieren",
            itemSuccessor: 240,
            itemValue: null
        },
        {
            itemId: 237,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 11 },
            itemType: "n",
            itemAge: [18, 24],
            itemText: "schraubt eine Dose oder Flasche auf",
            itemComment: "Drehbewegung der Hand auf horizontaler Ebene / problemlösendes Handeln",
            itemValue: null
        },
        {
            itemId: 238,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 12 },
            itemType: "i",
            itemAge: [24, 30],
            itemText: "fädelt einige große Perlen auf oder spießt mit der Gabel ein Stück Kartoffel auf etc.",
            itemComment: "Zusammenspiel von Auge-Hand-Koordination und taktiler Kontrolle",
            itemSuccessor: 245,
            itemValue: null
        },
        {
            itemId: 239,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 13 },
            itemType: "n",
            itemAge: [24, 30],
            itemText: "stapelt mehrere Gegenstände aufeinander (z.B. Bauklötze, Würfel, Teller)",
            itemComment: "probiert statische Verhältnisse aus",
            itemValue: null
        },
        {
            itemId: 240,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 14 },
            itemType: "i",
            itemAge: [30, 36],
            itemText: "imitiert Schreibbewegungen, zeichnet Rundformen, hält Stift im Faust- oder Kindergriff",
            itemComment: "taktile Kontrolle",
            itemSuccessor: 244,
            itemValue: null
        },
        {
            itemId: 241,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 15 },
            itemType: "n",
            itemAge: [30, 36],
            itemText: "hantiert mit Papier (faltet, wickelt Dinge ein, rollt es zusammen)",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 242,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 16 },
            itemType: "i",
            itemAge: [36, 48],
            itemText: "schneidet mit der Schere Schnipsel",
            itemComment: "",
            itemSuccessor: 246,
            itemValue: null
        },
        {
            itemId: 243,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 17 },
            itemType: "n",
            itemAge: [36, 48],
            itemText: "knetet mit Ton, Knete, Salzteig einfache  Formen (z.B. Kugel, Rolle, Platte)",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 244,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 18 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "hält den Stift korrekt im Dreipunktgriff",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 245,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 19 },
            itemType: "i",
            itemAge: [48, 60],
            itemText: "fädelt einen Faden in eine große Nadel alleine ein und näht einige Stiche",
            itemComment: "",
            itemSuccessor: 252,
            itemValue: null
        },
        {
            itemId: 246,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 20 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "schneidet mit der Schere entlang der Umrisse eines einfachen Bildes",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 247,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 21 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "klebt sorgfältig mit flüssigem Klebstoff oder Klebestift ",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 248,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 22 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "malt einfache Formen oder Figuren sauber aus",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 249,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 23 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "bindet eine Schleife selbständig",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 250,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 24 },
            itemType: "i",
            itemAge: [60, 72],
            itemText: "schreibt Buchstaben, Zahlen und seinen Namen korrekt und leserlich",
            itemComment: "",
            itemSuccessor: 251,
            itemValue: null
        },
        {
            itemId: 251,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 25 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "schreibt Sätze und einfache Texte korrekt und leserlich ",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 252,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 26 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "macht einfache Hand- oder Bastelarbeiten (z.B. Stricken, Häkeln, Kollagen, Bausätze etc.)",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 253,
            itemCategory: { categoryAbbrv: "HM", itemCategoryId: 27 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "handhabt verschiedene Werkzeuge und elektrische Geräte sicher, differenziert und und ausdauernd (z.B. Säge, Bohrer, Nähnadel, Waschmaschine)",
            itemComment: "kombiniert Kraft und Genauigkeit",
            itemValue: null
        },
        {
            itemId: 254,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 1 },
            itemType: "i",
            itemAge: [18, 24],
            itemText: "platziert erkennbar bewusst eine größere Anzahl von Gegenständen aufeinander, nebeneinander und ineinander; übernimmt Deutungen von Bezugspersonen (z.B. „Turm“ oder „Straße“), d.h. benennt die „Gebilde“ von sich aus nicht ",
            itemComment: "Übergang zum Konstruktionsspiel; experimentiert mit räumlichen Verhältnissen",
            itemSuccessor: 260,
            itemValue: null
        },
        {
            itemId: 255,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 2 },
            itemType: "i",
            itemAge: [18, 24],
            itemText: "ahmt eine vertraute Tätigkeit nach, ohne in eine andere Rolle zu schlüpfen (tut z.B. so, als ob er isst oder schläft, versorgt seine Puppe oder sein Stofftier)",
            itemComment: "Übergang zum Symbolspiel",
            itemSuccessor: 257,
            itemValue: null
        },
        {
            itemId: 256,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 3 },
            itemType: "i",
            itemAge: [24, 30],
            itemText: "legt einfachste Puzzle auf vorgestanzte Unterlage (3-10 Teile)",
            itemComment: "Formwahrnehmung",
            itemSuccessor: 265,
            itemValue: null
        },
        {
            itemId: 257,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 4 },
            itemType: "i",
            itemAge: [24, 30],
            itemText: "spielt einfache Alltagserfahrungen nach, d.h. übernimmt eine Rolle, allein (z.B. „Mutter und Kind“ mit der Puppe) oder mit einer Bezugsperson, die auf ihn eingeht",
            itemComment: "Beginn des Rollenspiels; symbolischesDenken",
            itemSuccessor: 261,
            itemValue: null
        },
        {
            itemId: 258,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 5 },
            itemType: "n",
            itemAge: [24, 30],
            itemText: "funktioniert im Spiel Gegenstände um (z.B. ein Bauklotz wird zum Auto, ein Stein zum Hammer)",
            itemComment: "Ausdruck der Symbolfunktion",
            itemValue: null
        },
        {
            itemId: 259,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 6 },
            itemType: "i",
            itemAge: [30, 36],
            itemText: "spielt mit Hilfestellung einfache Regelspiele (z.B. Memory, Domino, Würfelspiele mit Farbwürfeln)",
            itemComment: "Beginn des Regelspiels, braucht Unterstützung bei der Regeleinhaltung",
            itemSuccessor: 268,
            itemValue: null
        },
        {
            itemId: 260,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 7 },
            itemType: "i",
            itemAge: [36, 48],
            itemText: "baut  einfache, an einzelnen Merkmalen erkennbare Gebilde (z.B. Auto, Haus), benennt sie und bleibt über die aktuelle Situation hinaus bei der Benennung",
            itemComment: "",
            itemSuccessor: 266,
            itemValue: null
        },
        {
            itemId: 261,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 8 },
            itemType: "i",
            itemAge: [36, 48],
            itemText: "spielt allein ausführliche Rollenspiele",
            itemComment: "",
            itemSuccessor: 267,
            itemValue: null
        },
        {
            itemId: 262,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 9 },
            itemType: "i",
            itemAge: [36, 48],
            itemText: "übernimmt nach Anweisung einfache Rollen im gemeinsamen Spiel mit Gleichrangigen (z.B. Baby, Kind, Hund)",
            itemComment: "Beginn der Gruppenfähigkeit",
            itemSuccessor: 267,
            itemValue: null
        },
        {
            itemId: 263,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 10 },
            itemType: "i",
            itemAge: [36, 48],
            itemText: "spielt unter Anleitung Bewegungsspiele mit Regelcharakter (z.B. „der Plumpsack geht um“)",
            itemComment: "",
            itemSuccessor: 268,
            itemValue: null
        },
        {
            itemId: 264,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 11 },
            itemType: "f",
            itemAge: [36, 48],
            itemText: "verändert Regeln nach Belieben, weil er deren Sinn nicht versteht (z.B. beendet ein Spiel und beginnt ein anderes und kümmert sich nicht um die Regeln) ",
            itemComment: "das eigene Bedürfnis wiegt stärker als normative Vorgaben",
            itemSuccessor: 268,
            itemValue: null
        },
        {
            itemId: 265,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 12 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "macht vielteilige Puzzle (10 - 30 Teile auf vorgestanzter Unterlage)",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 266,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 13 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "baut differenzierte Konstruktionen, die mehrere Merkmale realer Dinge enthalten",
            itemComment: "anschauliches Denken",
            itemValue: null
        },
        {
            itemId: 267,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 14 },
            itemType: "i",
            itemAge: [48, 60],
            itemText: "spielt mit Anderen differenzierte Rollenspiele: Erfahrungen aus dem täglichen Leben (z.B. Kaufladen, Arzt etc.), Inhalte aus gesehenen oder gehörten Geschichten, eigene Phantasiegeschichten",
            itemComment: "Gruppenfähigkeit",
            itemSuccessor: 273,
            itemValue: null
        },
        {
            itemId: 268,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 15 },
            itemType: "i",
            itemAge: [48, 60],
            itemText: "spielt mit Regelverständnis einfache Regelspiele (z. B. Brett- und Kartenspiele wie Schwarzer Peter, Uno)",
            itemComment: "erfasst die Bedeutung von Regeln für das Gelingen eines gemeinschaftlichen Spiels",
            itemSuccessor: 270,
            itemValue: null
        },
        {
            itemId: 269,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 16 },
            itemType: "f",
            itemAge: [48, 60],
            itemText: "reagiert, wenn er verliert, mit heftigen Emotionen (z.B. weint, schreit, wirft die Spielfiguren um) oder bricht das Spiel ab",
            itemComment: "Leistungsorientierung, schwache Impulskontrolle bei ausbleibendem Erfolg",
            itemSuccessor: 275,
            itemValue: null
        },
        {
            itemId: 270,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 17 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "spielt gängige Regelspiele (z.B. Mensch ärgere dich nicht) und befolgt die Regeln",
            itemComment: "Regelverständnis; Impulskontrolle",
            itemValue: null
        },
        {
            itemId: 271,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 18 },
            itemType: "f",
            itemAge: [60, 72],
            itemText: "schummelt, weil er Verlieren nicht ertragen kann",
            itemComment: "Leistungsorientierung",
            itemSuccessor: 275,
            itemValue: null
        },
        {
            itemId: 272,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 19 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "achtet bei seinen Konstruktionen auf Funktionalität und praktische Verwendbarkeit",
            itemComment: "der technische Aspekt rückt in den Vordergrund; die bloß symbolische Bedeutung genügt nicht mehr",
            itemValue: null
        },
        {
            itemId: 273,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 20 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "spielt in seinen Rollenspielen differenziert den Alltag nach; Phantasie verliert an Bedeutung",
            itemComment: "Regelverständnis; Impulskontrolle",
            itemValue: null
        },
        {
            itemId: 274,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 21 },
            itemType: "i",
            itemAge: [72, 96],
            itemText: "spielt einfache strategische Spiele (z.B. Halma,Mühle,  Malefiz) mit eigenem Plan",
            itemComment: "Voraussetzung: Anfänge der Metakognition",
            itemSuccessor: 278,
            itemValue: null
        },
        {
            itemId: 275,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 22 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "erträgt es zu verlieren, strengt sich an, um zu gewinnen und hält Spielregeln zuverlässig ein",
            itemComment: "Impulskontrolle; Leistungsmotivation",
            itemValue: null
        },
        {
            itemId: 276,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 23 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "spielt selbst erfundene Theaterstücke (z.B. Zirkus), sorgt für Requisiten, probt manchmal mehrere Tage",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 277,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 24 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "macht anspruchsvolle Bausätze, Hand- oder Bastelarbeiten; kann dabei anschauliche und einfache schriftliche Pläne umsetzen",
            itemComment: "das Verstehen von bebilderten oder sprachlichen Anleitungen ist Voraussetzung, um komplexe, langfristige „Produktionen“ herzustellen",
            itemValue: null
        },
        {
            itemId: 278,
            itemCategory: { categoryAbbrv: "Sp", itemCategoryId: 25 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "spielt komplizierte strategische Regelspiele (z.B. Schach, Monopoly,Computerspiele) vorausschauend, d.h. versucht bei seinem Vorgehen die mögliche Strategie des Spielpartners zu stören und verfolgt gleichzeitig seinen eigenen Plan",
            itemComment: "ausgeprägte Metakognition",
            itemValue: null
        },
        {
            itemId: 279,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 1 },
            itemType: "i",
            itemAge: [12, 18],
            itemText: "hackt Punkte und kritzelt flüchtige Striche ",
            itemComment: "Freude an der Bewegung und am eigenen Produkt",
            itemSuccessor: 280,
            itemValue: null
        },
        {
            itemId: 280,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 2 },
            itemType: "i",
            itemAge: [18, 24],
            itemText: "zeichnet Pendelstriche mit gerundeten Ecken",
            itemComment: "Freude an der Bewegung und am eigenen Produkt",
            itemSuccessor: 282,
            itemValue: null
        },
        {
            itemId: 281,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 3 },
            itemType: "i",
            itemAge: [18, 24],
            itemText: "zeichnet Urknäuel (\"kreiselt\")",
            itemComment: "Freude an der Bewegung und am eigenen Produkt",
            itemSuccessor: 282,
            itemValue: null
        },
        {
            itemId: 282,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 4 },
            itemType: "i",
            itemAge: [24, 30],
            itemText: "zeichnet Gebilde mit sichtbarem Zentrum (z.B. gekreuzte Striche, markiert die Mitte des Urknäuels / der Spirale)",
            itemComment: "erste bewusste räumliche Strukturierung",
            itemSuccessor: 288,
            itemValue: null
        },
        {
            itemId: 283,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 5 },
            itemType: "i",
            itemAge: [30, 36],
            itemText: "benennt die Kritzeleien auf Befragen, Benennung kann wechseln",
            itemComment: "symbolisches Denken",
            itemSuccessor: 291,
            itemValue: null
        },
        {
            itemId: 284,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 6 },
            itemType: "i",
            itemAge: [30, 36],
            itemText: "zeichnet \"Strahlenfiguren\", d.h. kombiniert eine geschlossene Form (Kreis, Kasten) mit geraden Strichen",
            itemComment: "zeichnet geschlossene Formen, Strich wird willentlich geführt",
            itemSuccessor: 286,
            itemValue: null
        },
        {
            itemId: 285,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 7 },
            itemType: "i",
            itemAge: [36, 48],
            itemText: "zeichnet Grundformen (z.B. Kreise, Vierecke, Gitter, Striche, Punkte)",
            itemComment: "Ausdruck differenzierter Formwahrnehmung",
            itemSuccessor: 288,
            itemValue: null
        },
        {
            itemId: 286,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 8 },
            itemType: "i",
            itemAge: [36, 48],
            itemText: "zeichnet einfachste Kopffüßler (Kopf, Augen, Mund und Beine angedeutet)",
            itemComment: "vier Körperteile müssen angedeutet werden",
            itemSuccessor: 287,
            itemValue: null
        },
        {
            itemId: 287,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 9 },
            itemType: "i",
            itemAge: [48, 60],
            itemText: "zeichnet differenzierte Kopffüßler (zusätzlich z.B. Rumpf, Nase, Arme und Füße als einfache Striche)",
            itemComment: "vier zusätzliche Elemente gegenüber 3-4 J, d.h. mindestens 8",
            itemSuccessor: 293,
            itemValue: null
        },
        {
            itemId: 288,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 10 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "malt Konkretes: Häuser, Bäume, Blumen, Vögel, Landtiere, Fische, Sonne, Mond",
            itemComment: "kombiniert und variiert Grundformen (Kreis, Viereck, Dreieck), Zeichenschemata, Übergang zu anschaulichem Denken: differenzierte innere Bilder von Objekten",
            itemValue: null
        },
        {
            itemId: 289,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 11 },
            itemType: "i",
            itemAge: [48, 60],
            itemText: "wählt die typischen Perspektiven für die Darstellung eines Objektes (Haus von vorn)",
            itemComment: "",
            itemSuccessor: 299,
            itemValue: null
        },
        {
            itemId: 290,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 12 },
            itemType: "i",
            itemAge: [48, 60],
            itemText: "betont inhaltlich Wichtiges durch überproportionale Größe (Blume fast so groß wie der Baum)",
            itemComment: "anschauliches Denken: Individualität der inneren Bilder ohne Berücksichtigung der \"logischen\" Verhältnisse",
            itemSuccessor: 299,
            itemValue: null
        },
        {
            itemId: 291,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 13 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "sagt, was er malen will; Benennung bleibt stabil",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 292,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 14 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "setzt Figuren in Beziehung zueinander, z.B. Kind hält Hund an der Leine ",
            itemComment: "Bild erzählt eine Geschichte; anschauliches Denken",
            itemValue: null
        },
        {
            itemId: 293,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 15 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "malt differenzierte Menschen (z.B. mit plastischem Körper, d.h. Rumpf deutlich länger als breit, Arme und Beine als parallele Striche - zusätzlich z.B. Haare, Finger)",
            itemComment: " mindestens 12 Elemente; anschauliches Denken",
            itemValue: null
        },
        {
            itemId: 294,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 16 },
            itemType: "i",
            itemAge: [60, 72],
            itemText: "malt detailreiche Bilder (z.B. Fenster, Türen, Ziegel auf dem Dach, Schornstein sitzt noch schief, Sonne, Blumen oder Baum neben dem Haus)",
            itemComment: "anschauliches Denken",
            itemSuccessor: 298,
            itemValue: null
        },
        {
            itemId: 295,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 17 },
            itemType: "i",
            itemAge: [60, 72],
            itemText: "malt auch  das Unsichtbare (das Innere von Häusern, ganze Personen im Auto)",
            itemComment: "\"Röntgenbilder\"; anschauliches Denken: Person malt was sie weiß",
            itemSuccessor: 299,
            itemValue: null
        },
        {
            itemId: 296,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 18 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "wählt klare Blattaufteilung: Himmel oben, Gras unten",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 297,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 19 },
            itemType: "i",
            itemAge: [72, 96],
            itemText: "wählt typische Farben (Himmel blau, Baumstamm braun, Gras grün)",
            itemComment: "beginnendes konkret-logisches Denken: Wunsch nach realistischer Darstellung  Person malt, was sie sieht",
            itemSuccessor: 300,
            itemValue: null
        },
        {
            itemId: 298,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 20 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "malt komplexe Bilder (z.B. eine komplette Familie, Jahreszeiten mit den typischen Merkmalen, Landschaft mit Straße, Gebirge, Wald, Fluss)",
            itemComment: "beginnendes konkret-logisches Denken: Wunsch nach realistischer Darstellung, Person malt, was sie sieht",
            itemValue: null
        },
        {
            itemId: 299,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 21 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "malt nur das Sichtbare, Giebel- und  Seitenwand des Hauses; Schornstein steht senkrecht;  Baum groß, Blume klein",
            itemComment: " konkret-logisches Denken: korrekte Größenverhältnisse, Beginn der Perspektive",
            itemValue: null
        },
        {
            itemId: 300,
            itemCategory: { categoryAbbrv: "BA", itemCategoryId: 22 },
            itemType: "n",
            itemAge: [144, 216],
            itemText: "malt naturgetreu (z.B. Schattierungen, Figuren im Hintergrund kleiner als vorne)",
            itemComment: "Perspektive mit Fluchtpunkt; Plastizität und Überdeckung",
            itemValue: null
        },

    ];

}