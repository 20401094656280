import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';


const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function AssessmentTypeCard(props) {
    const classes = useStyles();

    const link = "/new-assessment/" + props.link;

    const [open, setOpen] = React.useState(false);

    const scales = [
        {
            id: 1,
            name: "Grundskala (BEP-KI-k)",
            description: "Schaubild und 5 Dimensionen (EM, So, DE, SpP, SpV) zur Auswahl.",
            options: [
                {
                    name: "Entwicklungsaufgaben",
                    description: "Schaubild zu den Entwicklungsaufgaben der Grundskala."
                }
            ]
        },
        {
            id: 2,
            name: "Ergänzungsskala (SeB)",
            description: "Schaubild und SeB-Skala.",
            options: []
        },
        {
            id: 3,
            name: "Erweiterungsskala (BEP-KI-e)",
            description: "Schaubild und 8 Dimensionen (W, RZÖ, LP-EH, LP-K, GM, HM, Sp, BA) zur Auswahl.",
            options: []
        }
    ];
    const products = [
        {
            name: "Grundskala",
            price: 12,
            scales: [1],
        },
        {
            name: "Ergänzungsskala",
            price: 12,
            scales: [2]
        },
        {
            name: "Grund- und Ergänzungsskala",
            price: 15,
            scales: [1, 2]
        },
        {
            name: "Grund- und Erweiterungsskala",
            price: 18,
            scales: [1, 3]
        },
        {
            name: "Grund- und Erweiterungs- und Ergänzungsskala",
            price: 20,
            scales: [1, 2, 3]
        }
    ];

    //const selectedProduct = (chosen) => products.filter(i => i.name === chosen);

    //const selectedScales = selectedProduct(props.name);

    const selectedProduct = products.find(i => i.name === props.name);
    console.log(selectedProduct);
    const selectedScales = scales.filter(i => selectedProduct.scales.includes(i.id));
    console.log(selectedScales);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {props.name}
                </Typography>
                <Typography variant="body2" component="p">
                    {props.text}
                </Typography>
            </CardContent>
            <CardActions>
                <Button variant="contained" onClick={handleClickOpen}>Neue Einschätzung</Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                >
                    <DialogTitle id="dialog-title">{"Kauf abschließen und neue Einschätzung starten?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-description" component="div" color="textPrimary">
                            <Grid container xs={12} sm spacing={2} direction="column">
                                <Grid item xs={12} sm container alignItems="baseline">
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Grid item xs>
                                            <Typography variant="subtitle1">
                                                Erhebung: {selectedProduct.name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(selectedProduct.price)}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {selectedScales.map((scale, i) =>
                                    <Grid>
                                        <Grid item xs={12} sm alignItems="baseline" style={{paddingLeft: "16px"}}>
                                            <Typography variant="subtitle1">
                                                {scale.name}
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                {scale.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                {selectedScales.map((scale, i) => {
                                        if (scale.options.length !== 0) {
                                            return scale.options.map((option, i) => {
                                                return(
                                                    <React.Fragment>
                                                        <Grid item xs={12} sm container alignItems="baseline">
                                                            <Grid item xs container direction="column" spacing={2}>
                                                                <Grid item xs>
                                                                    <Typography variant="subtitle1">
                                                                        Optionen
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} sm container alignItems="baseline">
                                                            <Grid item  xs container>
                                                                <Grid item xs={2}>
                                                                    <Switch disabled defaultChecked />
                                                                </Grid>
                                                                <Grid xs sm item alignItems="baseline" >
                                                                    <Typography variant="subtitle1">
                                                                        {option.name}
                                                                    </Typography>
                                                                    <Typography variant="body2" gutterBottom>
                                                                        {option.description}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography>+ 0,00 €</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    }
                                )}
                                <Divider variant="middle" />
                                <Grid item xs={12} sm container alignItems="baseline">
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Typography gutterBottom variant="subtitle1" style={{ fontWeight: 600 }}>
                                            Gesamtpreis
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography fontWeight="fontWeightBold" gutterBottom style={{ fontWeight: 600 }}>
                                            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(selectedProduct.price)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Typography gutterBottom variant="body2" style={{ marginTop: "0.5em" }}>
                                Gemäß Kleinunternehmerregelung nach § 19 UStG enthält die Rechnung keine Umsatzsteuer.
                            </Typography>
                            <Typography gutterBottom style={{ marginTop: "1em" }}>
                                Mit dem Klick auf <strong>JETZT KAUFEN</strong> startet die Einschätzung und Sie erhalten die Rechnung per E-Mail.
                            </Typography>
                            <Typography style={{ fontWeight: 600 }}>
                                HINWEIS: Durch Ihre Auswahl können Sie NUR die ausgewählten Skalen einschätzen!
                            </Typography>
                            <Typography style={{ fontWeight: 600 }}>
                                Die Einschätzung kann nicht unterbrochen oder beendet und erneut gestartet werden.
                            </Typography>
                            <Typography>
                                (Schließen Sie das Browserfenster während der Erhebung nicht.)
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Abbrechen
                        </Button>
                        <Button onClick={handleClose} color="primary" component={Link} to={link} autoFocus>
                            Jetzt kaufen
                        </Button>
                    </DialogActions>
                </Dialog>
            </CardActions>
        </Card>
    );
};