import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box'
import { Typography } from "@material-ui/core";

const categories = [
        {
            categoryAbbrv: "Em",
            categoryLabel: "Emotionale Entwicklung"
        },
        {
            categoryAbbrv: "So",
            categoryLabel: "Soziale Entwicklung"
        },
        {
            categoryAbbrv: "DE",
            categoryLabel: "Denkentwicklung"
        },
        {
            categoryAbbrv: "SpP",
            categoryLabel: "expr. Sprachentwicklung"
        },
        {
            categoryAbbrv: "SpV",
            categoryLabel: "rezep. Sprachentwicklung"
        },
        {
            categoryAbbrv: "W",
            categoryLabel: "Wahrnehmung"
        },
        {
            categoryAbbrv: "RZÖ",
            categoryLabel: "Raum, Zeit und Öffentlichkeit"
        },
        {
            categoryAbbrv: "LP-EH",
            categoryLabel: "Lebenspraxis - Ernährung und Haushalt"
        },
        {
            categoryAbbrv: "LP-K",
            categoryLabel: "Lebenspraxis - Körperpflege und Kleidung"
        },
        {
            categoryAbbrv: "GM",
            categoryLabel: "Grobmotorik"
        },
        {
            categoryAbbrv: "HM",
            categoryLabel: "Handmotorik"
        },
        {
            categoryAbbrv: "Sp",
            categoryLabel: "Spiel"
        },
        {
            categoryAbbrv: "BA",
            categoryLabel: "Bildhafter Ausdruck"
        }
    ];

export default class MainscaleChooseCategoryCard extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;

        let arrCat;
        if (this.props.extended) {
            arrCat = [1, 2, 3, 4, 5, 6 ,7, 8, 9, 10, 11, 12, 13];
        } else {
            arrCat = [1, 2, 3, 4, 5];
        }

        this.state = {
            chosenCategories: arrCat,
            error: false
        };

        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleCategoryChange(event) {
        var currentCats = this.state.chosenCategories;
        var id = parseInt(event.target.id);

        if (event.target.checked) {
            if (!currentCats.includes(id)) {
                currentCats.push(id);
                this.setState({ chosenCategories: currentCats });
            }
        } else {
            if (currentCats.includes(id)) {
                var index = currentCats.indexOf(id);
                currentCats.splice(index, 1);

                this.setState({ chosenCategories: currentCats });
            }
        }
    }

    getCurrentValue(cat) {
        if (this.state.chosenCategories.includes(cat)) {
            return true;
        } else {
            return false;
        }
    }

    handleSubmit() {
        if (this.state.chosenCategories.length === 0) {
            this.setState({ error: true });
        } else {
            this.setState({ error: false });

            var submitCategories = [];

            this.state.chosenCategories.sort((a, b) => a - b);

            for (var i = 0; i < this.state.chosenCategories.length; i++) {
                submitCategories.push(categories[this.state.chosenCategories[i] - 1].categoryAbbrv);
            }


            this.props.onSubmit(submitCategories);
        }
    }

    render() {
        console.log(JSON.stringify(this.state));
        let boughtCategories;
        if (!this.props.extended) {
            boughtCategories = categories.slice(0, 5);
        } else {
            boughtCategories = categories;
        }

        return (
            <Card>
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        Dimensionen
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        Wählen Sie die zu erfassenden Dimensionen aus:
                    </Typography>
                    <FormControl component="fieldset" error={this.state.error}>
                        <FormGroup>
                            {boughtCategories.map((cat, i) =>
                                <FormControlLabel
                                    control={<Checkbox checked={this.getCurrentValue(i + 1)} onChange={this.handleCategoryChange} name={cat.categoryAbbrv} id={String(i + 1)} />}
                                    label={cat.categoryLabel}
                                />
                            )}
                        </FormGroup>
                        {this.state.error && <FormHelperText>Sie müssen mindestens eine Dimension auswählen!</FormHelperText>}
                    </FormControl>
                    <Box mt={2}>
                        <Typography style={{ fontWeight: 600 }}>HINWEIS: Sie können Ihre Auswahl später nicht mehr korrigieren!</Typography>
                    </Box>
                </CardContent>
                <CardActions>
                    <Box>
                        <Button variant="contained" onClick={this.handleSubmit}>Einschätzung starten</Button>
                    </Box>
                </CardActions>
            </Card>
        );
    }
}