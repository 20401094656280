import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import NewAssessmentRoutes from './routes/NewAssessmentRoutes';
import RegisterFormCard from './RegisterFormCard'
import TextField from "@material-ui/core/TextField";

const drawerWidth = 280;
const maintenanceMode = false;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    // width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

export default function App() {
  const classes = useStyles();

  console.log("Render App Component...");

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" noWrap>
              BEPKI
            </Typography>
          </Toolbar>
        </AppBar>
        {/* <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <div className={classes.toolbar} />
          <Divider />
          <Menu />
        </Drawer> */}
        {maintenanceMode ? (
            <main className={classes.content}>

              <div className={classes.toolbar} />

              <Typography variant="h5" noWrap>
                Wartungsmodus
              </Typography>
              <Typography paragraph>
                Die Seite ist vorübergehend nicht erreichbar. Bitte haben Sie etwas geduld.
              </Typography>

            </main>
        ) : (
            <main className={classes.content}>

              <div className={classes.toolbar} />


              <Route path="/new-assessment" component={NewAssessmentRoutes} />
              <Route path="/register" component={RegisterFormCard} />
              <Route exact path="/" component={NewAssessmentRoutes} />

        </main>)}
      </div>
    </Router>
  );
}